// constant
import { SLIDES } from "redux/constants";
export const name = "slides";

// initial state
export const initialState = {
  citationSlides: {
    data: {},
    isLoading: false,
    error: '',
    errorGuids: []
  }
};

const {
  GET_SLIDES_DATA_FAILURE,
  GET_SLIDES_DATA_SUCCESS,
  GET_SLIDES_DATA_PENDING,
  CLEAR_SLIDES_DATA
} = SLIDES;

// reducer selectors
export const selectors = {
  getCitationSlides: (state) => state[name].citationSlides?.data,
  getCitationErrorSlides: (state) => state[name].citationSlides?.errorGuids,
  isCitationSlidesLoading: (state) => state[name].citationSlides.isLoading,
  isCitationSlidesError: (state) => state[name].citationSlides.error,
};


const setCitationSlidesError = (state, payload) => {
  return Object.assign({}, state, {
    citationSlides: {
      ...initialState.citationSlides,
      data: {
        ...state.citationSlides?.data,
      },
      error: payload.error.mesage,
      errorGuids: [
        ...state?.citationSlides?.errorGuids || [],
        payload.error.guid
      ]

    },
  });
};

const setCitationSlidesPending = (state) => {
  return Object.assign({}, state, {
    citationSlides: {
      ...initialState.citationSlides,
      errorGuids: [
        ...state.citationSlides?.errorGuids || [],
      ],
      data: {
        ...state.citationSlides?.data,
      },
      isLoading: true
    },
  });
};

const setCitationSlides = (state, payload) => {
  const { chatId, res } = payload;
  // const { citationSlides: { data } } = state;
  // data[chatId] = { ...res };

  return Object.assign({}, state, {
    citationSlides: {
      ...initialState.citationSlides,
      errorGuids: [
        ...state.citationSlides?.errorGuids || [],
      ],
      data: {
        ...state.citationSlides?.data,
        ...{ [chatId]: { ...res, ...state.citationSlides?.data[chatId] } }
      }
      // data: {
      //   ...state.citationSlides?.data,
      //   ...payload?.data
      // }
    },
  });
};

const clearCitationSlides = (state) => {
  return Object.assign({}, state, {
    citationSlides: {
      ...initialState.citationSlides,
    },
  });
};


export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SLIDES_DATA_PENDING:
      return setCitationSlidesPending(state);
    case GET_SLIDES_DATA_FAILURE:
      return setCitationSlidesError(state, payload);
    case GET_SLIDES_DATA_SUCCESS:
      return setCitationSlides(state, payload);
    case CLEAR_SLIDES_DATA:
      return clearCitationSlides(state, payload);
    default:
      return state;
  }
}
