import React, { useMemo, useEffect, useState, useRef } from "react";
// import DOMPurify from "dompurify";
import CONFIG from "config";
import LABELS from "labels";
import thoughtchainIcon from 'assets/images/thoughtchain_icon.svg';
import globeSmall from "assets/images/globe_small.svg";
import defaultSlidePreview from "assets/images/default_slide_preview.svg";
import copyIcon from "assets/images/copy.svg";
import copiedIcon from "assets/images/copied.svg";
import Image from "components/shared/Image";
import { ThumbsUpDown } from "components/ThumbsUpDown";


import "./Answer.scss";

import { parseAnswerToHtml } from "./AnswerParser";
import { handleCopy } from "utils/helpers/helpers";

const {
    API_URL: {
        LINK_PAGES_REDIRECT
    },
    GUID_MAPPING_QA_PROD_TEMP
} = CONFIG;


const combineGuidsWithSlides = (data) => {
    const guids = {};
    data.forEach((citation, i) => {
        const {
            title,
            page_no,
            identifier
        } = citation;

        //Temp mapping between prod and qa guids remove once we have the prod data api

        const qaGuid = GUID_MAPPING_QA_PROD_TEMP[identifier] || identifier;
        if (Object.keys(guids).indexOf(qaGuid) !== -1) {
            if (guids[qaGuid].slideNo.indexOf(page_no) === -1) {
                guids[qaGuid].slideNo.push(page_no);
            }
        } else {
            guids[qaGuid] = { slideNo: [page_no], title };
        }
    });
    return guids;
}

export const Answer = ({
    answer,
    counter,
    maxlimit,
    onThoughtProcessClicked,
    onCitationViewClick,
    smallPreviewCitations,
    callSlidesAPI,
    citationSlides,
    handleThumbsUpData,
    selected,
    isOpenThoughtchain
}) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer), [answer]);
    // const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const sanitizedAnswerHtml = parsedAnswer.answerHtml;

    const [citations, setCitations] = useState({});
    const [isCopied, setIsCopied] = useState(false);
    const copyRef = useRef(null);
    const { CITATIONS_HEADING, DOCVIZ_PREVIEW_NOT_AVAILABLE } = LABELS;

    useEffect(() => {
        const finalObj = combineGuidsWithSlides(parsedAnswer?.citations);
        setCitations(finalObj);
        if (smallPreviewCitations) {
            Object.keys(finalObj).forEach((guid) => {
                callSlidesAPI(guid, finalObj[guid].slideNo, parsedAnswer?.chatId);
            });
        }
    }, [parsedAnswer.citations, parsedAnswer.chatId, smallPreviewCitations, callSlidesAPI]);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 3000)
        }
    }, [isCopied])

    const currentCitationSlides = Object.keys(citationSlides).length ? citationSlides[parsedAnswer?.chatId] || {} : {};

    const onCopy = () => {
        setIsCopied(true);
        handleCopy(copyRef);
    }

    return (
        <div className={`answerContainer`}>
            <ul className="answerContainer__icons">
                <li>
                    <Image
                        src={globeSmall}
                        alt="globe-icon"
                    />
                </li>
                <li>
                    <ThumbsUpDown
                        finalSuccess={(feedback, comments) => handleThumbsUpData({ feedback, comments, chatId: parsedAnswer?.chatId })} />
                    {!isCopied ?
                        <Image
                            src={copyIcon}
                            className="answerContainer__clipboard"
                            onClick={onCopy}
                            title="Copy to clipboard"
                        />
                        :
                        <Image
                            src={copiedIcon}
                            className="answerContainer__clipboard"
                            title="Copied to clipboard"
                        />
                    }
                    <Image
                        src={thoughtchainIcon}
                        className="answerContainer__thoughtchain"
                        alt="arrow"
                        onClick={() => onThoughtProcessClicked(parsedAnswer)}
                        title="Open Thought Chain"
                    />
                </li>
            </ul>
            <div ref={copyRef} className={`answerContainer__inner ${(parsedAnswer?.chatId === selected && isOpenThoughtchain) ? "answerContainer__inner--active" : ""}`}>
                <div className={"answerText"} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                {!!Object.keys(currentCitationSlides).length ?
                    <div className="citation__wrap">
                        <h4 className={"citation__heading"}>{CITATIONS_HEADING}</h4>

                        {Object.keys(currentCitationSlides).map((guid, i) => {
                            const {
                                imgsrc,
                                res
                            } = currentCitationSlides[guid],
                                {
                                    slideNo
                                } = citations[guid];
                            const title = res?.data?.title || citations[guid].title;
                            return (
                                <div key={i} className={`citation citation__withImage ${imgsrc?.length >= 3 ? " citation__withImage-3" : (imgsrc?.length === 2 ? " citation__withImage-2" : "")}`}>
                                    <div className="citation__details">
                                        {
                                            !!imgsrc ?
                                                imgsrc?.map(({ imageSrc, slidenumber }, j) => {
                                                    return (

                                                        <div key={j} className="citation__imageswrap">
                                                            <a target="_blank" rel="noopener noreferrer" href={`${LINK_PAGES_REDIRECT}/kp/${guid}?opendocviz=true&slideno=${slidenumber}`}><span>{slidenumber}</span></a>
                                                            <img src={imageSrc} alt="slide-img" onClick={() => onCitationViewClick({ answer: parsedAnswer, imgSrc: imageSrc, doc: res?.data, guid, slidenumber })} />
                                                        </div>
                                                    )

                                                })
                                                :
                                                (
                                                    <div className="citation__imageswrap">
                                                        <a target="_blank" rel="noopener noreferrer" href={`${LINK_PAGES_REDIRECT}/kp/${guid}?opendocviz=true&slideno=${slideNo}`}><span>{slideNo}</span></a>
                                                        <img src={defaultSlidePreview} alt="default-slide-img" onClick={() => onCitationViewClick({ answer: parsedAnswer, imgSrc: '', doc: res?.data, guid, slidenumber: slideNo })} />
                                                        <div className="citation__unsupportedText">{DOCVIZ_PREVIEW_NOT_AVAILABLE}</div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                    <div>
                                        <a className={"citation__title"} title={title} target="_blank" rel="noopener noreferrer" href={`${LINK_PAGES_REDIRECT}/kp/${guid}?opendocviz=true`}>
                                            {`${title}`}
                                        </a>
                                    </div>
                                </div>
                            );

                        })}
                    </div>
                    : /**
                        *when there is nothing to render in the right hand side of the expression the default value of the expression is printed, to avoid this cast it into a boolean condition
                        *https://stackoverflow.com/questions/53048037/react-showing-0-instead-of-nothing-with-short-circuit-conditional-component
                    */
                    !!Object.keys(citations).length && (
                        <div>
                            <h4 className={"citation__heading"}>{CITATIONS_HEADING}</h4>
                            <div>
                                {Object.keys(citations).map((guid, i) => {
                                    const {
                                        title,
                                        slideNo
                                    } = citations[guid];
                                    return (
                                        <div key={i} className="citation">
                                            <a target="_blank" rel="noopener noreferrer" href={`${LINK_PAGES_REDIRECT}/kp/${guid}?opendocviz=true&slideno=${slideNo}`}><span>{slideNo}</span></a>
                                            <a className={"citation__title"} title={title} target="_blank" rel="noopener noreferrer" href={`${LINK_PAGES_REDIRECT}/kp/${guid}?opendocviz=true`}>
                                                {`${title}`}
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )
                }
                <div className="answerContainer__counter">
                    <span className={`answerContainer__countertext ${(counter >= Math.round(maxlimit * 0.7) && counter <= Math.round(maxlimit * 0.9)) ? "answerContainer__countertext-orange" : (counter >= maxlimit ? "answerContainer__countertext-red" : "")}`}>
                        {counter} of {maxlimit}
                    </span>
                </div>
            </div>
        </div>
    );
};
