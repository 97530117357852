// Constant
import { RESPONSE_DATA, FEEDBACK } from "redux/constants";

export const getResponseLoading = () => ({
    type: RESPONSE_DATA.RESPONSE_API_RESULTS_PENDING,
});

export const getResponseFailure = (error) => ({
    type: RESPONSE_DATA.RESPONSE_API_RESULTS_FAILURE,
    payload: {
        error: error
    }
});

export const getResponseSuccess = (response) => ({
    type: RESPONSE_DATA.RESPONSE_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});





export const postFeedbackResponseLoading = () => ({
    type: FEEDBACK.POST_FEEDBACK_RESPONSE_PENDING,
});

export const postFeedbackResponseFailure = (error) => ({
    type: FEEDBACK.POST_FEEDBACK_RESPONSE_FAILURE,
    payload: {
        error: error
    }
});

export const postFeedbackResponseSuccess = (response) => ({
    type: FEEDBACK.POST_FEEDBACK_RESPONSE_SUCCESS,
    payload: {
        response: response
    }
});


export const postFeedbackResponse = ({ chatId, feedback, comments }) => ({
    type: FEEDBACK.POST_FEEDBACK_RESPONSE,
    payload: {
        chatId, feedback, comments
    }
});
