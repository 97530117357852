import { put, call, takeEvery } from 'redux-saga/effects';
// Constant
import { CASES } from "redux/constants";
// Config
import CONFIG from "config";
// Label
import LABELS from "labels";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import {
    getCaseDetailsPending,
    getCaseDetailsSuccess,
    getCaseDetailsFailure,
    getRelatedCaseDetailPending,
    getRelatedCaseDetailSuccess,
    getRelatedCaseDetailFailure,
    getCasesMetaDetailsPending,
    getCasesMetaDetailsSuccess,
    getCasesMetaDetailsFailure
} from "./case.action";

export function* getCasesMetaDetails(action) {
    const { payload: { caseNumbers } } = action;
    const {
        API_URL: {
            GET_CASES_META_DETAILS
        },
        X_API_KEY,
    } = CONFIG;
    try {
        yield put(getCasesMetaDetailsPending());
        const res = yield call(doAxiosRequest, {
            method: 'POST',
            endpoint: GET_CASES_META_DETAILS(),
            // params: action.payload,
            params: {
                cases: caseNumbers
            },
            headers: {
                'x-api-key': X_API_KEY,
            }
        });
        if (res?.error.code === 200 && res?.data) {
            yield put(getCasesMetaDetailsSuccess(res?.data));
        } else {
            yield put(getCasesMetaDetailsFailure(res?.error));
        }

    } catch (error) {
        yield put(getCasesMetaDetailsFailure(error));
    }
}
export function* getCaseDetails(action) {
    const { payload: { projectId, isRelatedCase, typeofCase } } = action;
    const { CP: { NO_CASE_AVAILABLE } } = LABELS;
    try {
        if (isRelatedCase) {
            yield put(getRelatedCaseDetailPending(projectId, '', typeofCase));
        } else {
            yield put(getCaseDetailsPending());
        }

        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: CONFIG.API_URL.GET_CASE_DETAILS(projectId),
            headers: {
                'x-api-key': process.env.REACT_APP_ENRICH_API_DOMAIN_KEY,
            }
        });
        if (res && res.doc && res.doc[0]) {
            if (isRelatedCase) {
                yield put(getRelatedCaseDetailSuccess(projectId, res.doc[0], typeofCase));
            } else {
                yield put(getCaseDetailsSuccess(res.doc[0]));
            }
        } else {
            if (isRelatedCase) {
                yield put(getRelatedCaseDetailFailure(NO_CASE_AVAILABLE));
            } else {
                yield put(getCaseDetailsFailure(NO_CASE_AVAILABLE));
            }
        }


    } catch (error) {
        yield put(getCaseDetailsFailure(error));
    }
}

export default function* CasesSaga() {
    try {
        yield takeEvery(CASES.GET_CASE_DETAILS, getCaseDetails);
        yield takeEvery(CASES.GET_CASES_META_DETAILS, getCasesMetaDetails);
    } catch (error) {
        yield put(moduleFailure(error, 'CasesSaga'));
    }
}