import React, { useEffect, useState } from "react";
import Image from "components/shared/Image";
import LABELS from 'labels';

import chatIconGrey from "assets/images/chat_icon_grey.svg";
import binIcon from "assets/images/bin.svg";
import facheck from "assets/images/facheck.svg";
import fatimes from "assets/images/fatimes.svg";

import './ChatSessionHistory.scss';
import { scrollElementToPosition } from "utils/helpers/helpers";

export const ChatSessionHistory = ({
    chatSession = [],
    onClickActionHandler,
    activeChatSessionId,
    onClickDeleteHandler
}) => {

    // const [active, setActive] = useState(null);
    const chatElementRef = React.useRef(null);
    const chatContainerRef = React.useRef(null);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (chatContainerRef.current && chatElementRef.current)
            scrollElementToPosition(chatContainerRef.current, chatElementRef.current);
    }, [activeChatSessionId])

    const onClickActionHandlerImpl = (chatSessionId) => {
        // setActive(chatSessionId);
        onClickActionHandler(chatSessionId);
        setIsDeleting(false);
    }

    const handleChatDeleteToggle = () => {
        setIsDeleting(!isDeleting);
    }
    const handleDelete = () => {
        onClickDeleteHandler(activeChatSessionId)
    }

    const {
        NEW_CHAT
    } = LABELS;

    return (
        <section className="chatsessionhistory__list" ref={chatContainerRef}>
            {chatSession.map((session, index) => {
                const {
                    chat_session_id,
                    title
                } = session;

                return (
                    <div
                        className={`chatsessionhistory__wrap ${(activeChatSessionId === chat_session_id) ? "chatsessionhistory__wrap--active" : ""}`}
                        key={`${chat_session_id}-${index}`}
                        ref={(activeChatSessionId === chat_session_id) ? chatElementRef : null}
                    >
                        <div className="chatsessionhistory__item" onClick={() => onClickActionHandlerImpl(chat_session_id)}>
                            <Image src={chatIconGrey} alt="chatIconGrey" />
                            <span className="chatsessionhistory__title">{!!title ? title : NEW_CHAT}</span>
                        </div>
                        {chat_session_id === activeChatSessionId &&
                            <span className="chatsessionhistory__icons">
                                {
                                    isDeleting ?
                                        <span>
                                            <span className="fa fa-check" aria-hidden="true" onClick={handleDelete}>
                                                <Image src={facheck} alt="check" />
                                            </span>
                                            <span className="fa fa-times" aria-hidden="true" onClick={handleChatDeleteToggle}>
                                                <Image src={fatimes} alt="times" />
                                            </span>
                                        </span> :
                                        <Image src={binIcon} alt="binIcon" className="chatsessionhistory__delete" onClick={handleChatDeleteToggle} />
                                }
                            </span>
                        }
                    </div>
                );
            })}
        </section >
    )
}