import React from "react";

import Image from "components/shared/Image";
import logoIcon from "assets/images/bigglobe.gif";

import "./LoadingIcon.scss";

export const LoadingIcon = () => {

    return (
        <div className="loading-icon__wrap">
            <div className="loading-icon__item">
                <Image src={logoIcon} alt="logo" />
            </div>

            <span className="loading-icon__info">Generating answers for you...</span>
        </div>
    );
};
