// Constant
import { USER_CHAT_SESSION } from "redux/constants";

const {
    GET_ACTIVE_USER_CHAT_SESSION,
    ACTIVE_USER_CHAT_SESSION_FAILURE,
    ACTIVE_USER_CHAT_SESSION_PENDING,
    ACTIVE_USER_CHAT_SESSION_SUCCESS,
    CREATE_USER_CHAT_SESSION,
    CREATE_USER_CHAT_SESSION_FAILURE,
    CREATE_USER_CHAT_SESSION_PENDING,
    CREATE_USER_CHAT_SESSION_SUCCESS,
    GET_CHAT_HISTORY_PENDING,
    GET_CHAT_HISTORY_FAILURE,
    GET_CHAT_HISTORY_SUCCESS,
    GET_CHAT_HISTORY,
    CLEAR_CHAT_HISTORY,
    SET_CHAT_SESSION_HISTORY_SECTION_TOGGLE,
    SET_CHAT_SESSION_HISTORY_SECTION_DISABLE,
    GET_CHAT_SESSION_HISTORY,
    GET_CHAT_SESSION_HISTORY_FAILURE,
    GET_CHAT_SESSION_HISTORY_PENDING,
    GET_CHAT_SESSION_HISTORY_SUCCESS,
    UPDATE_USER_CHAT_SESSION_SUCCESS,
    DELETE_CHAT_SESSION,
    DELETE_CHAT_SESSION_PENDING,
    DELETE_CHAT_SESSION_FAILURE,
    DELETE_CHAT_SESSION_SUCCESS,
} = USER_CHAT_SESSION;

// Get Active User Chat Session
export const getActiveUserChatSessionPending = () => ({
    type: ACTIVE_USER_CHAT_SESSION_PENDING,
});

export const getActiveUserChatSessionFailure = (error) => ({
    type: ACTIVE_USER_CHAT_SESSION_FAILURE,
    payload: {
        error: error
    }
});

export const getActiveUserChatSessionSuccess = (data) => ({
    type: ACTIVE_USER_CHAT_SESSION_SUCCESS,
    payload: {
        data
    }
});

export const getActiveUserChatSession = () => ({
    type: GET_ACTIVE_USER_CHAT_SESSION,
});


// Create User Chat Session
export const createUserChatSessionPending = () => ({
    type: CREATE_USER_CHAT_SESSION_PENDING,
});

export const createUserChatSessionFailure = (error) => ({
    type: CREATE_USER_CHAT_SESSION_FAILURE,
    payload: {
        error: error
    }
});

export const createUserChatSessionSuccess = (data) => ({
    type: CREATE_USER_CHAT_SESSION_SUCCESS,
    payload: {
        data
    }
});

export const updateUserChatSessionSuccess = (chatSessionId) => ({
    type: UPDATE_USER_CHAT_SESSION_SUCCESS,
    payload: {
        data: {
            chat_session_id: chatSessionId
        }
    }
});

export const createUserChatSession = (data) => ({
    type: CREATE_USER_CHAT_SESSION,
    payload: {
        data
    }
});

export const getChatHistoryPending = () => ({
    type: GET_CHAT_HISTORY_PENDING,
});

export const getChatHistoryFailure = (error) => ({
    type: GET_CHAT_HISTORY_FAILURE,
    payload: {
        error: error
    }
});

export const getChatHistorySuccess = (data) => ({
    type: GET_CHAT_HISTORY_SUCCESS,
    payload: {
        data
    }
});

export const clearChatHistory = () => ({
    type: CLEAR_CHAT_HISTORY,
});

export const getChatHistory = (sessionId) => ({
    type: GET_CHAT_HISTORY,
    payload: {
        sessionId
    }
});

export const getChatSessionHistoryPending = () => ({
    type: GET_CHAT_SESSION_HISTORY_PENDING,
});

export const getChatSessionHistoryFailure = (error) => ({
    type: GET_CHAT_SESSION_HISTORY_FAILURE,
    payload: {
        error: error
    }
});

export const getChatSessionHistorySuccess = (data) => ({
    type: GET_CHAT_SESSION_HISTORY_SUCCESS,
    payload: {
        data
    }
});

export const getChatSessionHistory = () => ({
    type: GET_CHAT_SESSION_HISTORY,
});

export const setChatSessionHistorySectionToggle = (data) => ({
    type: SET_CHAT_SESSION_HISTORY_SECTION_TOGGLE,
    payload: {
        data
    }
});

export const deleteChatSession = (data, flag) => ({
    type: DELETE_CHAT_SESSION,
    payload: {
        sessionId: data,
        flag
    }
});

export const deleteChatSessionPending = () => ({
    type: DELETE_CHAT_SESSION_PENDING,
});

export const deleteChatSessionFailure = (error) => ({
    type: DELETE_CHAT_SESSION_FAILURE,
    payload: {
        error: error
    }
});

export const deleteChatSessionSuccess = (data) => ({
    type: DELETE_CHAT_SESSION_SUCCESS,
    payload: {
        data
    }
});

export const setHistoryChatSectionDisabled = (data) => ({
    type: SET_CHAT_SESSION_HISTORY_SECTION_DISABLE,
    payload: {
        data
    }
});
