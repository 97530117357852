import React, { PureComponent } from "react";
import { connect } from "react-redux";
import history from "redux/history";
import { Dimmer, Loader } from 'semantic-ui-react'
import { UpdatePageUrlWithoutRefresh } from "utils/helpers/helpers";
import { Button } from "components/shared/Button";
import LABELS from "labels";
import { setChatSessionHistorySectionToggle, getChatSessionHistory, deleteChatSession } from "redux/actions/userchatsession/userchatsession.actions";
import { selectors as userChatSessionSelector } from "redux/reducers/userchatsession/userchatsession_reducer";
// import { ArrowIcon } from "components/shared/ArrowIcon";
import { ChatSessionHistory } from "components/ChatSessionHistory/ChatSessionHistory";
import "./ChatHistory.scss";

const {
    NEW_CHAT,
    CHAT_HISTORY,
    PROPOSAL_ACCELERATOR: { SESSION }
} = LABELS;
export class ChatHistoryContainer extends PureComponent {

    componentDidMount = () => {
        const {
            getUserChatSessionHistory
        } = this.props;

        getUserChatSessionHistory();
    }

    hideChatSessionHistorySection = (data) => {
        const {
            toggleChatSessionHistorySection
        } = this.props;

        toggleChatSessionHistorySection(data);
    }

    showSelectedChatSessionChatHistory = (chatSessionId) => {
        UpdatePageUrlWithoutRefresh('/' + chatSessionId, history);
    }

    chatSessionDeleteHandler = (chatSessionId) => {
        const {
            activeUserChatSessionId,
            deleteUserChatSessionImpl
        } = this.props;

        if (chatSessionId === activeUserChatSessionId) {
            deleteUserChatSessionImpl(chatSessionId, true);
            UpdatePageUrlWithoutRefresh('/', history);
        } else {
            deleteUserChatSessionImpl(chatSessionId, false);
        }
    }

    render = () => {
        const {
            chatSessionHistory,
            activeUserChatSessionId,
            onNewChatSessionClick,
            disabled,
            isPaHeading
        } = this.props;

        return (
            <div className={`chathistory ${disabled ? 'chathistory-disabled' : ''}`}>
                {/* {!showChatSessionHistorySection && */}
                {disabled && <Dimmer active inverted>
                    <Loader />
                </Dimmer>}
                <div className="chathistory__wrap">
                    <div className="chathistory__heading">
                        <h4>{!isPaHeading ? CHAT_HISTORY : SESSION}</h4>
                        {/* <ArrowIcon 
                            arrowDirection={"left"}
                            onClickActionHandler={() => this.hideChatSessionHistorySection(true)}
                            customStyle={"chathistory__arrowStyle"}
                        /> */}
                    </div>
                    {chatSessionHistory && chatSessionHistory.length > 0 && <ChatSessionHistory
                        chatSession={chatSessionHistory}
                        onClickActionHandler={(data) => this.showSelectedChatSessionChatHistory(data)}
                        activeChatSessionId={activeUserChatSessionId}
                        onClickDeleteHandler={(data) => this.chatSessionDeleteHandler(data)}
                    />}
                    <Button className="newchatbutton green--500" onClick={() => onNewChatSessionClick()}>
                        + {NEW_CHAT}
                    </Button>
                </div>
                {/* } */}
            </div>
        );
    };
}

ChatHistoryContainer.propTypes = {

};

ChatHistoryContainer.defaultProps = {
    isPaHeading: false
};

const mapStateToProps = (state) => ({
    showChatSessionHistorySection: userChatSessionSelector.isChatSessionHistorySectionToggle(state),
    chatSessionHistory: userChatSessionSelector.getChatSessionHistory(state),
    activeUserChatSessionId: userChatSessionSelector.getActiveUserChatSession(state),
});

const mapDispatchToProps = (dispatch) => ({
    // createNewChatSessionForUser: (data) => dispatch(createUserChatSession(data)),
    toggleChatSessionHistorySection: (data) => dispatch(setChatSessionHistorySectionToggle(data)),
    getUserChatSessionHistory: () => dispatch(getChatSessionHistory()),
    // updateUserChatSession: (data) => dispatch(updateUserChatSessionSuccess(data)),
    // getUserChatHistory: (sessionId) => dispatch(getChatHistory(sessionId)),
    deleteUserChatSessionImpl: (data, flag) => dispatch(deleteChatSession(data, flag)),
});

export const ChatHistory = connect(mapStateToProps, mapDispatchToProps)(ChatHistoryContainer);