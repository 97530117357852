import React, { useState } from "react";
import sendIcon from "assets/images/send_icon.svg";
import Image from "components/shared/Image";

import "./QuestionInput.scss";

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }) => {
    const [question, setQuestion] = useState("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (e) => {
        const { value } = e.target;
        if (!value) {
            setQuestion("");
        } else if (value.length <= 1000) {
            setQuestion(value);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <div className="question-container">
            <input
                className="question-container__input"
                type="text"
                placeholder={placeholder}
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                disabled={disabled}
            />
            <div className="question-container__icon">
                <div
                    className={`question-container__icon__button ${sendQuestionDisabled ? "question-container__icon__button--disabled" : ""}`}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                >
                    <Image
                        src={sendIcon}
                    />
                </div>
            </div>
        </div>
    );
};
