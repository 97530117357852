import { put, call, takeEvery, all } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { SLIDES } from "redux/constants";

import {
    getSlidesDataFailure,
    getSlidesDataSuccess,
    getSlidesDataPending,
} from "./slides.actions";

const getSlideImageCode = (slidesData, slideNumbers) => {
    return slideNumbers.map(page_no => {
        return call(function* () {
            const slideUrl = slidesData?.url + "/" + slidesData?.s3Object + "/" + slidesData?.previews[page_no - 1]?.large?.hqUrl;
            const { DOCVIZ_X_API_KEY } = CONFIG;
            const response = yield call(doAxiosRequest, {
                endpoint: slideUrl,
                method: 'get',
                headers: {
                    'x-api-key': DOCVIZ_X_API_KEY
                },
                responsetype: 'arraybuffer',
                // cancelToken: newSource.token
            });
            const data = response;
            const imageData = `data:image/png;base64,${Buffer.from(data, 'binary').toString('base64')}`;

            return { slidenumber: page_no, imageSrc: imageData };
        });
    });
}

export function* getSlidesData(action) {
    try {
        const {
            payload: {
                guid,
                slideNumber,
                chatId
            }
        } = action;
        const { SITECORE_API_KEY, API_URL: { GET_SLIDE_DATA } } = CONFIG;
        yield put(getSlidesDataPending());
        const endpoint = GET_SLIDE_DATA(guid);
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': SITECORE_API_KEY
            }
        });
        const slidesData = res.data;
        if (slidesData?.previews?.length) {
            const datawithImage = yield all(getSlideImageCode(slidesData, slideNumber));
            yield put(getSlidesDataSuccess({ [guid]: { res, imgsrc: datawithImage } }, chatId));
        } else {
            yield put(getSlidesDataSuccess({ [guid]: { res } }, chatId));
        }
    } catch (error) {
        console.error("error: ", error)
        yield put(getSlidesDataFailure({ error: error?.message, guid: action.payload.guid }));
    }
}

export default function* slidesSaga() {
    try {
        yield takeEvery(SLIDES.GET_SLIDES_DATA, getSlidesData);
    } catch (error) {
        yield put(moduleFailure(error, 'slides Saga'));
    }
}