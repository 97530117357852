// Constant
import { SLIDES } from "redux/constants";


export const getSlidesDataPending = () => ({
    type: SLIDES.GET_SLIDES_DATA_PENDING,
});

export const getSlidesDataFailure = (error) => ({
    type: SLIDES.GET_SLIDES_DATA_FAILURE,
    payload: {
        error: error
    }
});

export const getSlidesDataSuccess = (res, chatId) => ({
    type: SLIDES.GET_SLIDES_DATA_SUCCESS,
    payload: {
        res,
        chatId
    }
});

export const getSlidesData = (guid, slideNumber, chatId) => ({
    type: SLIDES.GET_SLIDES_DATA,
    payload: {
        guid,
        slideNumber,
        chatId
    }
});

export const clearSlidesData = () => ({
    type: SLIDES.CLEAR_SLIDES_DATA,
});
