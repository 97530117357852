// Axios Utility
import { doAxiosRequest } from "config-axios";
// Constant
import { CASE_CODE } from "redux/constants";
//component config
import COMP_CONFIG from "components/CaseNumberSelector/CaseNumberSelector.config";
// Config
import CONFIG from "config";

// actions
export const actions = {
    // 
    getCaseCodeData: (code) => async (dispatch) => {
        dispatch({
            type: CASE_CODE.CASE_CODE_PENDING,
            payload: "",
        });
        try {
            const axiosConfig = {
                method: "get",
                endpoint: CONFIG.API_URL.GET_CASE_CODE(code),
                headers: {
                    'x-api-key': CONFIG.X_API_KEY
                }
            };
            const data = await doAxiosRequest(axiosConfig);
            if (data && data.data) {
                dispatch({
                    type: CASE_CODE.CASE_CODE_SUCCESS,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: CASE_CODE.CASE_CODE_WITH_NO_CONTENT,
                    payload: COMP_CONFIG.NO_CONTENT_TEXT,
                });
            }

        } catch (error) {
            dispatch({
                type: CASE_CODE.CASE_CODE_FAILURE,
                payload: COMP_CONFIG.INVALID_CASE_TEXT,
            });
        }
    },

    resetErrorState: () => async dispatch => {
        dispatch({
            type: CASE_CODE.CASE_CODE_ERROR_RESET,
        });
    }
};