import React from 'react'
import Image from "components/shared/Image";
import LABELS from 'labels';
import {
  CardView,
  OverlayView,
  // CardViewSubject, GlobalStyle, OverlayViewSubject, SUBSCRIBERS 
} from '@enrich/docviz';
import styled from "styled-components";
import CONFIG from 'config';
import defaultSlidePreview from "assets/images/default_slide_preview.svg";
import { Tooltip } from "components/Tooltip";


import crossIcon from 'assets/images/cross.svg';
import collectionIcon from 'assets/images/collection_icon.svg';
import downloadIcon from 'assets/images/download_icon.svg';
import fileIconPptx from 'assets/images/fileIcon-pptx.svg';
import "./CitationSection.scss";
import { mapMetadataToDoc, docvizOtherProps } from 'utils/helpers/helpers';

export const OverlayWrapper = styled(OverlayView)`
  .docViz-overlay, .docViz-modal{
    position: static !important;
    padding: 0;
  }
  .docViz-modal .docviz-sidebar > div {
    padding-left: 0px;
  }
  .docviz-backdrop, .relevancyFeedback, .expand-button, .slideSelection-bar--white, .slideCheckboxContainer--single{
    display:none;
  }
  .docViz-modal {

    label, .ui.checkbox {
      display: none;
    }
    .attachment {
      .attachTitle {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .slideSelection-action--2 {
      display: none;
    }

    .docViz-header__actions {
      img.ui.image {
        max-width: 27px;
      }
    }

    .headerMask.row {
      .col-md-1 {
        position: static;
      }
    }
    .thumbnail {
      right: 10%;
    }

  }
`;

export const CardViewWrapper = styled(CardView)`
  .previewBtn:hover {
    text-decoration: none;
    cursor: initial !important;
  }
  .card-actions-block {
    button { 
      img.ui.image {
        max-width: 24px;
      }
    }
  }
`;

const CitationSection = (props) => {
  const { onClose, docvizData, imgSrc } = props;
  const { CITATIONS_HEADING, ADD_TO_COLLECTION, DOWNLOAD, DOCVIZ_PREVIEW_NOT_AVAILABLE,
    FILE_EXTENSION: {
      PPTX
    }
  } = LABELS;
  let docvizDoc = {};
  let currentSlide = 0;
  if (Object.keys(docvizData).length) {
    const { doc, guid } = docvizData;
    currentSlide = docvizData.slidenumber;
    docvizDoc = mapMetadataToDoc(guid, doc);
    // console.log('docvizDoc', docvizDoc, doc);
  }

  const fileIconUrl = docvizDoc?.attachmentFileType === PPTX ? fileIconPptx : '';
  const { UI_URL: { DOWNLOAD_FILE } } = CONFIG;
  console.log('docvizDoc', docvizDoc);
  return (
    <div className="citation-section__wrap">
      <div className='citation-section__heading'>
        <div className="citation-section__heading-left">
          <h3>{CITATIONS_HEADING}</h3>
        </div>
        <ul className='citation-section__heading-right'>
          {!!imgSrc &&
            <li for="taxonomy_tooltip_pilldisable" className="citation-section__pill citation-section__pill-disable">
              <Tooltip id="taxonomy_tooltip_pilldisable"
                position='top'
                icon={
                  <>
                    <span className="citation-section__iconWrap">
                      <Image
                        src={collectionIcon}
                        alt="collection icon"
                        disabled={!!imgSrc}
                      />
                    </span>
                    <h5>{ADD_TO_COLLECTION}</h5>
                  </>
                }
              >
                Coming soon
              </Tooltip>
            </li>
          }

          {!!imgSrc &&
            <li className="citation-section__pill">
              <a
                href={DOWNLOAD_FILE(docvizData?.guid)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="citation-section__iconWrap">
                  <Image
                    src={downloadIcon}
                    alt="download icon"
                  />
                </span>
                <span className='citation-section__pill--text'>{DOWNLOAD}</span>
              </a>

            </li>
          }
          <li className="citation-section__pill citation-section__cross">
            <Image
              src={crossIcon}
              alt="cross icon"
              onClick={onClose}
            />
          </li>
        </ul>
      </div>
      <div className="citation-section__body">
        {
          !!imgSrc ?
            !!Object.keys(docvizDoc).length && (
              <OverlayWrapper
                doc={{
                  ...docvizDoc,
                  currentSlideIndex: currentSlide - 1
                }}
                {...docvizOtherProps()}
              />
            ) :
            (
              <div className="citation-section__imageswrap">
                <div className="citation-section__filename">
                  <img className="citation-section__filename-image" src={fileIconUrl} alt="fileIcon-img" />
                  <span className="citation-section__filename-text">{docvizDoc?.fileName}</span>
                </div>
                <img src={defaultSlidePreview} alt="default-slide-img" />
                <div className="citation-section__unsupportedText">{DOCVIZ_PREVIEW_NOT_AVAILABLE}</div>
              </div>
            )
        }
        <div>

        </div>
      </div>
    </div>
  )
}

export { CitationSection };