// Library Imports
import React from "react";
import { connect } from "react-redux";
import { ChatHistory } from "containers/ChatHistory";
import { Taxonomy } from "containers/Taxonomy";
import CONSTANTS from "globalConstants";
import { Button } from "components/shared/Button";


// Custom Components
import { SideBar } from "components/SideBar";
import { Layout } from "containers/Layout";
import { CaseNumberSelector } from "components/CaseNumberSelector";
import { selectors as caseSelectors } from 'redux/reducers/casecode/case_reducer';
import { getCasesMetaDetails } from 'redux/actions/casecode/case.action';

// Constants, Labels, Configs
import LABELS from "labels";

// Actions
import { getPolyhierarchyApiData } from "redux/actions/polyhierarchy/polyhierarchy.actions";

//Helper
import { getUserId } from "utils/auth/auth";

import "./ProposalAccelerator.scss";
// import CONFIG from "config";
class ProposalAcceleratorContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            subjectTags: [],
            isShowForm: true,
            paError: false,
            contextValue: "",
            associatedCases: [],
            userHrid: ""

        }
    }

    getUserHrid = async () => {
        const hrid = await getUserId();
        this.setState({ userHrid: hrid })
    }
    handleSubmitButton = () => {
        const { contextValue, bstTags, subjectTags, associatedCases } = this.state;

        this.setState({ isShowForm: false });

        const userSessionData = {
            bstTags,
            subjectTags,
            associatedCases,
            contextValue,
        }
        localStorage.setItem(`userSessionData_${this.state.userHrid}`, JSON.stringify(userSessionData))
    }

    handleContext = (e) => {
        this.setState({ contextValue: e.target.value });
    }

    handleTaxonomyChange = (data, name) => {
        this.setState({ [name]: data });
    }
    handlePaError = (errorState) => {
        this.setState({ paError: errorState });
    }
    takeToForm = () => {
        this.setState({ isShowForm: true });
    }
    componentDidMount = () => {
        const { getPolyhierarchyApiData } = this.props;
        getPolyhierarchyApiData();
        this.getUserHrid();
    }
    componentDidUpdate = (prevProps) => {
        const { associatedCases } = this.props;
        if (prevProps.associatedCases !== associatedCases && associatedCases.length) {
            this.setState({
                associatedCases,
            });
        }

    }
    handleCaseNumber = (data) => {
        const { getCasesMetaDetails } = this.props;
        if (data?.casenumber) {
            getCasesMetaDetails([data?.casenumber]);
        }
    }
    render() {

        const {
            TBDBS: {
                SEARCH_SUBJECT,
                SEARCH_FPA_IPA_INTERNAL
            },
        } = CONSTANTS;
        const {
            ADMIN_SELECTPA_HEADING,
            SUBJECT_TAXONOMY_HEADING,
            IPA_FPA_MODAL_SEARCH_PLACEHOLDER
        } = LABELS;

        const { isShowForm, subjectTags, paError, contextValue } = this.state;
        const { associatedCases } = this.props;

        return (
            <Layout id="chat" layoutClass="layout__proposalAccelerator">
                <div className={`main proposalAccelerator`}>
                    <SideBar />
                    <ChatHistory
                        isPaHeading={true}
                    />
                    <div className="proposalAccelerator__rightsec">
                        {isShowForm ?
                            <>
                                <div className="proposalAccelerator__form">
                                    <div className="proposalAccelerator__field">
                                        <CaseNumberSelector
                                            name="caseNumbers"
                                            onChange={this.handleCaseNumber}
                                            associatedCases={associatedCases}
                                            onErrorStateChange={this.handlePaError}
                                        />
                                    </div>
                                    <div className="proposalAccelerator__field">
                                        <label>Context</label>
                                        <input type="text" placeholder="Add context" value={contextValue} onChange={this.handleContext} />
                                    </div>
                                    <div className="proposalAccelerator__field">
                                        <Taxonomy
                                            label={ADMIN_SELECTPA_HEADING}
                                            placeholder={ADMIN_SELECTPA_HEADING}
                                            modalHeader={ADMIN_SELECTPA_HEADING}
                                            isApplicableForPolyHerarchi
                                            taxonomyName={[
                                                {
                                                    label: "Industry",
                                                    key: "IPA",
                                                },
                                                {
                                                    label: "Functional",
                                                    key: "FPA",
                                                },
                                                {
                                                    label: "BCG Internal",
                                                    key: "BCG_INTERNAL"
                                                }
                                            ]}
                                            onChange={(data) => {
                                                this.handleTaxonomyChange(data, "bstTags")
                                            }}
                                            activeIndex={0}
                                            preFillData={[
                                                [],
                                                [],
                                                []
                                            ]}
                                            responseTypeSearchKeyName={{
                                                id: "id",
                                                name: "name",
                                            }}
                                            isShowParentTagsTooltip
                                            typeSearchplaceholder={IPA_FPA_MODAL_SEARCH_PLACEHOLDER}
                                            isShowNoResultInTypeAhead
                                            characterCount={2}
                                            taxonomyTypeSearchName={[
                                                { searchKey: SEARCH_FPA_IPA_INTERNAL }
                                            ]}
                                            showTaxonomyTagCompletePath={true}
                                        />
                                    </div>
                                    <div className="proposalAccelerator__field">
                                        <Taxonomy
                                            key={"id"}
                                            label={SUBJECT_TAXONOMY_HEADING}
                                            placeholder={SUBJECT_TAXONOMY_HEADING}
                                            modalHeader={SUBJECT_TAXONOMY_HEADING}
                                            taxonomyName={[
                                                { label: "Subject", key: "SUBJECT" },
                                            ]}
                                            onChange={(data) => {
                                                this.handleTaxonomyChange(data[0], "subjectTags");
                                            }}
                                            responseTypeSearchKeyName={{
                                                id: "id",
                                                name: "name",
                                            }}
                                            preFillData={[subjectTags]}
                                            // className={errorState && errorState['subjectTags'] ? 'txbase__error' : ''}
                                            isShowParentTagsTooltip
                                            typeSearchplaceholder={SUBJECT_TAXONOMY_HEADING}
                                            characterCount={2}
                                            taxonomyTypeSearchName={[
                                                { searchKey: SEARCH_SUBJECT }
                                            ]}
                                        />
                                    </div>
                                    <div className="proposalAccelerator__submitcontainer">
                                        <Button className="button_pa" onClick={this.handleSubmitButton} disabled={paError}>Submit</Button>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="proposalAccelerator__results">
                                <h2>Step 2: Starts here...</h2>
                                <Button className="button_pa" onClick={this.takeToForm}>Back</Button>
                            </div>
                        }
                    </div>
                </div>
            </Layout >
        )
    }
}

const mapStateToProps = (state) => ({
    associatedCases: caseSelectors.getCasesMetaDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
    getPolyhierarchyApiData: () => dispatch(getPolyhierarchyApiData()),
    getCasesMetaDetails: (caseNumbers) => dispatch(getCasesMetaDetails(caseNumbers)),
});


export const ProposalAccelerator = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalAcceleratorContainer);

export { ProposalAcceleratorContainer };
