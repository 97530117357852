import {
  SUGGESTED_QUERY_DATA
} from 'redux/constants';
export const name = 'suggestedQuery';
const initialState = {
  loading: false,
  error: '',
  response: [],
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getResponse: (state) => state[name].response
};

const {
  SUGGESTED_QUERY_API_RESULTS_FAILURE,
  SUGGESTED_QUERY_API_RESULTS_PENDING,
  SUGGESTED_QUERY_API_RESULTS_SUCCESS
} = SUGGESTED_QUERY_DATA;

export function reducer(state = initialState, action) {
  const { payload = {} } = action;
  const { response = [], error } = payload;
  switch (action.type) {
    case SUGGESTED_QUERY_API_RESULTS_PENDING:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case SUGGESTED_QUERY_API_RESULTS_FAILURE:
      return {
        ...state,
        error: error,
        loading: false,
      };
    case SUGGESTED_QUERY_API_RESULTS_SUCCESS:
      return {
        ...state,
        response: [
          ...response
        ],
        loading: false,
        error: ''
      };
    default:
      return state;
  }
}
