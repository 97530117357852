import CONFIG from "config";
export function sendToCookie(name, value, expiryTime) {
    // Encode value in order to escape semicolons, commas, and whitespace
    var cookie = name + "=" + encodeURIComponent(value);
    cookie += '; path=/';
    if (typeof expiryTime === "number") {
        /* Sets the max-age attribute so that the cookie expires
        after the specified number of days */
        cookie += "; max-age=" + (expiryTime * 24 * 60 * 60); // [no of days]
    } else if (typeof expiryTime === "string") {
        cookie += "; expires=" + expiryTime; // [GMT time stamp string]
    }
    document.cookie = cookie;
}

export function deleteCookie(name) {
    // Deleting a cookie
    sendToCookie(name, '', CONFIG.PAST_DATE);
}


export function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie?.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr?.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}