import { put, all, call } from 'redux-saga/effects';
import { globalSagaFailure } from "./global.action";
import ResponseSaga from "redux/actions/response/response.saga";
import SuggestedQuerySaga from './actions/suggested_query/suggestedquery.saga';
import userSessionSaga from "redux/actions/userchatsession/userchatsession.saga";
import slidesSaga from "redux/actions/slides/slides.saga";
import CasesSaga from 'redux/actions/casecode/case.saga';
import TaxonomySaga from "redux/actions/taxonomy/Taxonomy.saga.js";
import polyhierarchySaga from "redux/actions/polyhierarchy/polyhierarchy.saga.js";

export default function* globalSaga() {
    try {
        yield all([
            call(ResponseSaga),
            call(TaxonomySaga),
            call(polyhierarchySaga),
            call(SuggestedQuerySaga),
            call(userSessionSaga),
            call(slidesSaga),
            call(CasesSaga)
        ]);
    } catch (err) {
        yield put(globalSagaFailure(err))
    }
}