// Constant
import { SUGGESTED_QUERY_DATA } from "redux/constants";

const {
    SUGGESTED_QUERY_API_RESULTS_FAILURE,
    SUGGESTED_QUERY_API_RESULTS_PENDING,
    SUGGESTED_QUERY_API_RESULTS_SUCCESS
} = SUGGESTED_QUERY_DATA
export const getSuggestedQueryLoading = () => ({
    type: SUGGESTED_QUERY_API_RESULTS_PENDING,
});

export const getSuggestedQueryFailure = (error) => ({
    type: SUGGESTED_QUERY_API_RESULTS_FAILURE,
    payload: {
        error: error
    }
});

export const getSuggestedQuerySuccess = (response) => ({
    type: SUGGESTED_QUERY_API_RESULTS_SUCCESS,
    payload: {
        response: response
    }
});