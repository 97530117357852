export const RESPONSE_DATA = {
  QUERY_RESPONSE: "QUERY_RESPONSE",
  RESPONSE_API_RESULTS_PENDING: "RESPONSE_API_RESULTS_PENDING",
  RESPONSE_API_RESULTS_SUCCESS: "RESPONSE_API_RESULTS_SUCCESS",
  RESPONSE_API_RESULTS_FAILURE: "RESPONSE_API_RESULTS_FAILURE"
}

export const SUGGESTED_QUERY_DATA = {
  SUGGESTED_QUERY: "SUGGESTED_QUERY",
  SUGGESTED_QUERY_API_RESULTS_PENDING: "SUGGESTED_QUERY_API_RESULTS_PENDING",
  SUGGESTED_QUERY_API_RESULTS_SUCCESS: "SUGGESTED_QUERY_API_RESULTS_SUCCESS",
  SUGGESTED_QUERY_API_RESULTS_FAILURE: "SUGGESTED_QUERY_API_RESULTS_FAILURE"
}

// Notification toastify
export const ERROR = "ERROR";
export const INFO = "INFO";
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";
export const DISMISS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS";

// Upload
export const UPLOAD_S3 = {
  GET_UPLOAD_S3: "GET_UPLOAD_S3",
  GET_UPLOAD_S3_PENDING: "GET_UPLOAD_S3_PENDING",
  GET_UPLOAD_S3_SUCCESS: "GET_UPLOAD_S3_SUCCESS",
  GET_UPLOAD_S3_FAILURE: "GET_UPLOAD_S3_FAILURE",
  FETCH_PRESIGNURL_PENDING: "FETCH_PRESIGNURL_PENDING",
  FETCH_PRESIGNURL_FAILURE: "FETCH_PRESIGNURL_FAILURE",
  FETCH_PRESIGNURL_SUCCESS: "FETCH_PRESIGNURL_SUCCESS",
}

export const API_GET_ALL_MATERIALS_PENDING = "API_GET_ALL_MATERIALS_PENDING";
export const API_GET_ALL_MATERIALS_FAILURE = "API_GET_ALL_MATERIALS_FAILURE";
export const API_GET_ALL_MATERIALS_SUCCESS = "API_GET_ALL_MATERIALS_SUCCESS";

export const API_GET_MATERIAL_PENDING = "API_GET_MATERIAL_PENDING";
export const API_GET_MATERIAL_FAILURE = "API_GET_MATERIAL_FAILURE";
export const API_GET_MATERIAL_SUCCESS = "API_GET_MATERIAL_SUCCESS";

export const API_SAVE_MATERIAL_PENDING = "API_SAVE_MATERIAL_PENDING";
export const API_SAVE_MATERIAL_SUCCESS = "API_SAVE_MATERIAL_SUCCESS";
export const API_SAVE_MATERIAL_FAILURE = "API_SAVE_MATERIAL_FAILURE";

export const API_SAVE_UPLOAD_MATERIAL_PENDING =
  "API_SAVE_UPLOAD_MATERIAL_PENDING";
export const API_SAVE_UPLOAD_MATERIAL_SUCCESS =
  "API_SAVE_UPLOAD_MATERIAL_SUCCESS";
export const API_SAVE_UPLOAD_MATERIAL_FAILURE =
  "API_SAVE_UPLOAD_MATERIAL_FAILURE";

export const REDIRECT_METADATA_PAGE = "REDIRECT_METADATA_PAGE";


// Enitilement Actions
export const ENTITLEMENTS = {
  GET_ENTITLEMENTS: "GET_ENTITLEMENTS",
  API_GET_ENTITLEMENTS_PENDING: "API_GET_ENTITLEMENTS_PENDING",
  API_GET_ENTITLEMENTS_SUCCESS: "API_GET_ENTITLEMENTS_SUCCESS",
  API_GET_ENTITLEMENTS_FAILURE: "API_GET_ENTITLEMENTS_FAILURE",

  // Actions for checking whether the user is site admin/editor or not
  CHECK_SITE_ADMIN: "CHECK_SITE_ADMIN",
  API_CHECK_SITE_ADMIN_PENDING: "API_CHECK_SITE_ADMIN_PENDING",
  API_CHECK_SITE_ADMIN_SUCCESS: "API_CHECK_SITE_ADMIN_SUCCESS",
  API_CHECK_SITE_ADMIN_FAILURE: "API_CHECK_SITE_ADMIN_FAILURE",
}


// Global Actions
export const GLOBAL = {
  SHOW_PAGELOADER: "SHOW_PAGELOADER",
  HIDE_PAGELOADER: "HIDE_PAGELOADER",
  SHOW_FULLPAGELOADER: "SHOW_FULLPAGELOADER",
  HIDE_FULLPAGELOADER: "HIDE_FULLPAGELOADER",
  FULLPAGELOADER_ERROR: "FULLPAGELOADER_ERROR",
  DISABLE_BODY_OVERFLOW: "DISABLE_BODY_OVERFLOW",
  ENABLE_BODY_OVERFLOW: "ENABLE_BODY_OVERFLOW",
  LOGGEDIN_USER_DATA: "LOGGEDIN_USER_DATA"
};

export const PEOPLE_DATA = {
  API_PEOPLE_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_PEOPLE_RESULTS_SUCCESS: "API_PEOPLE_RESULTS_SUCCESS",
  API_PEOPLE_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS"
};

export const CCO_DATA = {
  GET_CCO_RESULTS: "GET_CCO_RESULTS",
  GET_CCO_RESULTS_PENDING: "GET_CCO_RESULTS_PENDING",
  GET_CCO_RESULTS_SUCCESS: "GET_CCO_RESULTS_SUCCESS",
  GET_CCO_RESULTS_FAILURE: "GET_CCO_RESULTS_FAILURE",
};

export const USER_CHAT_SESSION = {
  GET_ACTIVE_USER_CHAT_SESSION: "GET_ACTIVE_USER_CHAT_SESSION",
  ACTIVE_USER_CHAT_SESSION_PENDING: "GET_ACTIVE_USER_CHAT_SESSION_PENDING",
  ACTIVE_USER_CHAT_SESSION_SUCCESS: "GET_ACTIVE_USER_CHAT_SESSION_SUCCESS",
  ACTIVE_USER_CHAT_SESSION_FAILURE: "GET_ACTIVE_USER_CHAT_SESSION_FAILURE",
  CREATE_USER_CHAT_SESSION: "CREATE_USER_CHAT_SESSION",
  CREATE_USER_CHAT_SESSION_PENDING: "CREATE_USER_CHAT_SESSION_PENDING",
  CREATE_USER_CHAT_SESSION_SUCCESS: "CREATE_USER_CHAT_SESSION_SUCCESS",
  CREATE_USER_CHAT_SESSION_FAILURE: "CREATE_USER_CHAT_SESSION_FAILURE",
  GET_CHAT_HISTORY: "GET_CHAT_HISTORY",
  GET_CHAT_HISTORY_PENDING: "GET_CHAT_HISTORY_PENDING",
  GET_CHAT_HISTORY_SUCCESS: "GET_CHAT_HISTORY_SUCCESS",
  GET_CHAT_HISTORY_FAILURE: "GET_CHAT_HISTORY_FAILURE",
  CLEAR_CHAT_HISTORY: "CLEAR_CHAT_HISTORY",
  SET_CHAT_SESSION_HISTORY_SECTION_TOGGLE: "SET_CHAT_SESSION_HISTORY_SECTION_TOGGLE",
  SET_CHAT_SESSION_HISTORY_SECTION_DISABLE: "SET_CHAT_SESSION_HISTORY_SECTION_DISABLE",
  GET_CHAT_SESSION_HISTORY: "GET_CHAT_SESSION_HISTORY",
  GET_CHAT_SESSION_HISTORY_PENDING: "GET_CHAT_SESSION_HISTORY_PENDING",
  GET_CHAT_SESSION_HISTORY_SUCCESS: "GET_CHAT_SESSION_HISTORY_SUCCESS",
  GET_CHAT_SESSION_HISTORY_FAILURE: "GET_CHAT_SESSION_HISTORY_FAILURE",
  UPDATE_USER_CHAT_SESSION_SUCCESS: "UPDATE_USER_CHAT_SESSION_SUCCESS",
  DELETE_CHAT_SESSION: "DELETE_CHAT_SESSION",
  DELETE_CHAT_SESSION_PENDING: "DELETE_CHAT_SESSION_PENDING",
  DELETE_CHAT_SESSION_FAILURE: "DELETE_CHAT_SESSION_FAILURE",
  DELETE_CHAT_SESSION_SUCCESS: "DELETE_CHAT_SESSION_SUCCESS",
};

// Authorization Actions
export const AUTH = {
  GET_SMARTLOGIC_BEARER_TOKEN: "GET_SMARTLOGIC_BEARER_TOKEN",
  GET_SMARTLOGIC_BEARER_TOKEN_PENDING: "GET_SMARTLOGIC_BEARER_TOKEN_PENDING",
  GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS: "GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS",
  GET_SMARTLOGIC_BEARER_TOKEN_FAILURE: "GET_SMARTLOGIC_BEARER_TOKEN_FAILURE"
};

export const TYPEAHEAD_DATA = {
  API_TYPEAHEAD_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_TYPEAHEAD_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS",
  API_TYPEAHEAD_DATA_CLEAR: "API_TYPEAHEAD_DATA_CLEAR"
};


export const SLIDES = {
  GET_SLIDES_DATA_PENDING: "GET_SLIDES_DATA_PENDING",
  GET_SLIDES_DATA_FAILURE: "GET_SLIDES_DATA_FAILURE",
  GET_SLIDES_DATA_SUCCESS: "GET_SLIDES_DATA_SUCCESS",
  CLEAR_SLIDES_DATA: "CLEAR_SLIDES_DATA",
  GET_SLIDES_DATA: "GET_SLIDES_DATA"
};

export const FEEDBACK = {
  POST_FEEDBACK_RESPONSE_PENDING: "POST_FEEDBACK_RESPONSE_PENDING",
  POST_FEEDBACK_RESPONSE_FAILURE: "POST_FEEDBACK_RESPONSE_FAILURE",
  POST_FEEDBACK_RESPONSE_SUCCESS: "POST_FEEDBACK_RESPONSE_SUCCESS",
  POST_FEEDBACK_RESPONSE: "POST_FEEDBACK_RESPONSE"
};
export const CASES = {
  GET_CASE_DETAILS: "GET_CASE_DETAILS",
  API_GET_CASE_DETAILS_PENDING: "API_GET_CASE_DETAILS_PENDING",
  API_GET_CASE_DETAILS_SUCCESS: "API_GET_CASE_DETAILS_SUCCESS",
  API_GET_CASE_DETAILS_FAILURE: "API_GET_CASE_DETAILS_FAILURE",
  GET_RELATED_CASE_DETAIL: "GET_RELATED_CASE_DETAIL",
  API_GET_RELATED_CASE_DETAIL_PENDING: "API_GET_RELATED_CASE_DETAIL_PENDING",
  API_GET_RELATED_CASE_DETAIL_SUCCESS: "API_GET_RELATED_CASE_DETAIL_SUCCESS",
  API_GET_RELATED_CASE_DETAIL_FAILURE: "API_GET_RELATED_CASE_DETAIL_FAILURE",
  GET_CASES_META_DETAILS: "GET_CASES_META_DETAILS",
  API_GET_CASES_META_DETAILS_PENDING: "API_GET_CASES_META_DETAILS_PENDING",
  API_GET_CASES_META_DETAILS_SUCCESS: "API_GET_CASES_META_DETAILS_SUCCESS",
  API_GET_CASES_META_DETAILS_FAILURE: "API_GET_CASES_META_DETAILS_FAILURE"
}
export const CASE_CODE = {
  CASE_CODE_PENDING: "CASE_CODE_PENDING",
  CASE_CODE_SUCCESS: "CASE_CODE_SUCCESS",
  CASE_CODE_WITH_NO_CONTENT: "CASE_CODE_WITH_NO_CONTENT",
  CASE_CODE_FAILURE: "CASE_CODE_FAILURE",
  CASE_CODE_ERROR_RESET: "CASE_CODE_ERROR_RESET"
};

export const TAXONOMY = {
  GET_TAXONOMY: "GET_TAXONOMY",
  GET_TYPE_SEARCH_DATA: "GET_TYPE_SEARCH_DATA",
  API_GET_TAXONOMY_PENDING: "API_GET_FUNCTIONAL_TAXONOMY_PENDING",
  API_GET_TAXONOMY_SUCCESS: "API_GET_FUNCTIONAL_TAXONOMY_SUCCESS",
  API_GET_TAXONOMY_FAILURE: "API_GET_FUNCTIONAL_TAXONOMY_FAILURE",

  API_GET_TYPESEARCH_PENDING: "API_GET_FUNCTIONAL_TYPESEARCH_PENDING",
  API_GET_TYPESEARCH_SUCCESS: "API_GET_FUNCTIONAL_TYPESEARCH_SUCCESS",
  API_GET_TYPESEARCH_FAILURE: "API_GET_FUNCTIONAL_TYPESEARCH_FAILURE",

  GET_SL_PARENT_TAGS_DATA: "GET_SL_PARENT_TAGS_DATA",
  GET_SL_PARENT_TAGS_PENDING: "GET_SL_PARENT_TAGS_PENDING",
  GET_SL_PARENT_TAGS_SUCCESS: "GET_SL_PARENT_TAGS_SUCCESS",
  GET_SL_PARENT_TAGS_FAILURE: "GET_SL_PARENT_TAGS_FAILURE",
  RESET_ALL_SL_PARENT_TAGS: "RESET_ALL_SL_PARENT_TAGS",

  CREATE_POLY_HIERARCHI_GUIDS_OBJ_CONFIG: "CREATE_POLY_HIERARCHI_GUIDS_OBJ_CONFIG",
  CALL_SL_PARENT_TAG_FLAG: "CALL_SL_PARENT_TAG_FLAG"
};

// Polyhierarchy Actions
export const POLYHIERACHY = {
  GET_POLYHIERARCHY_API_DATA: 'GET_POLYHIERARCHY_API_DATA',
  POLYHIERARCHY_PENDING: 'POLYHIERARCHY_PENDING',
  POLYHIERARCHY_SUCCESS: 'POLYHIERARCHY_SUCCESS',
  POLYHIERARCHY_FAILURE: 'POLYHIERARCHY_FAILURE'
}