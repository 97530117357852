// import React from "react";

export function parseAnswerToHtml(answer) {
    const {
        citations,
        followup_questions,
        chat_id,
        confidence_text,
        thoughts,
        response
    } = answer;

    return {
        answerHtml: response,
        chatId: chat_id,
        confidence: confidence_text,
        thoughts,
        citations,
        followupQuestions: followup_questions
    };
}
