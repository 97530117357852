import React, { Fragment, PureComponent } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import CONFIG from "config";
import { ErrorBoundary } from "containers/ErrorBoundary/ErrorBoundary";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import "./Layout.scss";
import { setLoggedInUserData } from "redux/global.action";
import { Header } from "components/Header";
import { getUserId } from "utils/auth/auth";
// import { ChatHistory } from "containers/ChatHistory";

export class LayoutContainer extends PureComponent {

  componentDidMount = () => {
    const { IS_WALKME_ENABLED } = CONFIG;
    if (IS_WALKME_ENABLED) {
      this.walkMeInitialize();
    }
    this.usabillaSetup();
    this.getLoggedInUSerDetails();
  }

  usabillaSetup = () => {
    if (window) {
      window.lightningjs || function (n) {
        var e = "lightningjs";
        function t(e, t) {
          var r, i, a, o, d, c;
          return t && (t += (/\?/.test(t) ? "&" : "?") + "lv=1"), n[e] || (r = window, i = document, a = e, o = i.location.protocol, d = "load", c = 0, function () {
            n[a] = function () {
              var t = arguments, i = this, o = ++c, d = i && i != r && i.id || 0; function s() {
                return s.id = o, n[a].apply(s, arguments)
              } return (e.s = e.s || []).push([o, d, t]), s.then = function (n, t, r) {
                var i = e.fh[o] = e.fh[o] || [], a = e.eh[o] = e.eh[o] || [], d = e.ph[o] = e.ph[o] || [];
                return n && i.push(n), t && a.push(t), r && d.push(r), s
              }, s
            }; var e = n[a]._ = {}; function s() { e.P(d), e.w = 1, n[a]("_load") } e.fh = {}, e.eh = {}, e.ph = {}, e.l = t ? t.replace(/^\/\//, ("https:" == o ? o : "http:") + "//") : t, e.p = { 0: +new Date }, e.P = function (n) { e.p[n] = new Date - e.p[0] }, e.w && s(), r.addEventListener ? r.addEventListener(d, s, !1) : r.attachEvent("onload", s); var l = function () {
              function n() {
                return ["<!DOCTYPE ", o, "><", o, "><head></head><", t, "><", r, ' src="', e.l, '"></', r, "></", t, "></", o, ">"].join("")
              } var t = "body", r = "script", o = "html", d = i[t];
              if (!d) return setTimeout(l, 100); e.P(1);
              var c, s = i.createElement("div"), h = s.appendChild(i.createElement("div")), u = i.createElement("iframe");
              s.style.display = "none", d.insertBefore(s, d.firstChild).id = "lightningjs-" + a, u.frameBorder = "0", u.id = "lightningjs-frame-" + a, /MSIE[ ]+6/.test(navigator.userAgent) && (u.src = "javascript:false"), u.allowTransparency = "true", h.appendChild(u);
              try {
                u.contentWindow.document.open()
              } catch (n) {
                e.domain = i.domain, c = "javascript:var d=document.open();d.domain='" + i.domain + "';", u.src = c + "void(0);"
              }
              try {
                var p = u.contentWindow.document; p.write(n()), p.close()
              } catch (e) {
                u.src = c + 'd.write("' + n().replace(/"/g, String.fromCharCode(92) + '"') + '");d.close();'
              } e.P(2)
            };
            e.l && l()
          }()), n[e].lv = "1", n[e]
        } var r = window.lightningjs = t(e);
        r.require = t, r.modules = n
      }({});
      if (
        !navigator.userAgent.match(
          /Android|BlackBerry|BB10|iPhone|iPad|iPod|Opera Mini|IEMobile/i
        )
      ) {
        window.usabilla_live = window.lightningjs.require(
          "usabilla_live",
          "//w.usabilla.com/a29e5fb4a586.js"
        );
      }
    }
  }

  getLoggedInUSerDetails = async () => {
    const hrid = await getUserId();
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs,
      },
      setLoggedInUserData
    } = this.props;
    const peopleData = await getPeopleDataUsingIDs([hrid]);
    if (peopleData && peopleData[0])
      setLoggedInUserData(peopleData[0]);
  }

  walkMeInitialize = () => {
    if (window) {
      const { API_URL: { WALKME_SCRIPT_URL } } = CONFIG;
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = WALKME_SCRIPT_URL;
      const s = document.getElementsByTagName('script')[0];
      if (!!s) {
        s.parentNode.insertBefore(walkme, s);
      }
      window._walkmeConfig = { smartLoad: true };
    }
  }

  goHome = () => {
    const { history } = this.props;
    history.push(CONFIG.UI_URL.HOME);
  };

  render = () => {
    const {
      children,
      layoutClass,
      isBodyScrollable
    } = this.props;

    return (
      <div className={`layout layout__page ${!isBodyScrollable ? 'layout--unscrollable' : ''}`}>
        <Header />
        <Fragment>
          <ErrorBoundary>
            <Container
              className={`layout__container layout__fix ${layoutClass}`}
              fluid
            >
              {children}
            </Container>
          </ErrorBoundary>
        </Fragment>
      </div>
    );
  };
}

LayoutContainer.propTypes = {
  children: PropTypes.node,
  layoutClass: PropTypes.string
};

LayoutContainer.defaultProps = {
  layoutClass: "",
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
  setLoggedInUserData: (data) => dispatch(setLoggedInUserData(data))
});

export const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
);