import React from "react";
import { ProfileIcon } from "components/ProfileIcon";

import "./UserChatMessage.scss";

export const UserChatMessage = ({ message }) => {
    return (
        <div className="userChatMessage__container">
            <div className="userChatMessage__profileicon">
                <ProfileIcon/>
            </div>
            <div className="userChatMessage__message">{message}</div>
        </div>
    );
};
