import LABELS from 'labels';
import CONFIG from 'config';
import authService from 'redux/auth';
import { getFromLocalStorageAsJSON } from 'utils/localStorage/localStorage';
import { store } from "../../redux/store";

/** 
 * @description method to get current window location 
*/

export const getmyCurrentLocation = () => {
  if (window) {
    return window.location.href;
  }
  return null;
}

/**
 * Returns the text from a HTML string
 * 
 * @param {html} String The html string
 */
export const stripHtml = (html) => {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

/**
 * @description trim string by charlimit but last word intac
 * @param {yourString} String 
 * @returns trimmedString
 */

export const noWordsTrimmingInString = (yourString, maxLength) => {
  let trimmedString = '';
  //trim the string to the maximum length
  trimmedString = yourString.substr(0, maxLength);
  //re-trim if we are in the middle of a word and 
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))

  return trimmedString;

}

// Check if the browser is IE
export const isIE = () => {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf('MSIE ');
  // IE 11
  const trident = ua.indexOf('Trident/');
  return msie > -1 || trident > -1;
}


/**
 * Opens the url in new tab or same tab
 * @param {url} String
 * @param {newTab} boolean
 */
export const openURL = (url, newTab) => {
  if (newTab) {
    window.open(url, '_blank');
  }
  else {
    window.open(url);
  }
}


export const removeCurlyBracketFromId = (id) => {
  return id?.indexOf('{') > -1 ? id.substring(1, id.length - 1) : id
}

export const removeCurlyBrackets = (ids = []) => {
  if (Array.isArray(ids))
    return ids.map(id => removeCurlyBracketFromId(id));
  else
    return removeCurlyBracketFromId(ids)
}

export const isValidData = (content) => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  if (typeof content === "undefined" || !content.length || content.trim === "" || content.toLowerCase() === 'null' || content.trim().toLowerCase() === RESTRICTED) {
    return false
  } else {
    return true;
  }
}

export const addColonToValidData = (content) => {
  return isValidData(content) ? content + " : " : ""
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTimeStampDate = (date) => {
  if (date && date.length > 0) {
    const dateArr = date.split("-")
    return new Date(`${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`).getTime()
  }
}

export const sortArrayObjectByDate = (data, key) => {
  if (data || data.length > 0) {
    return data.sort((a, b) => getTimeStampDate(b.data[key]) - getTimeStampDate(a.data[key]))
  }
}

export const replaceInLabel = (originalString, searchValue, replaceWith) => {
  if (Array.isArray(replaceWith)) {
    let replacestr = originalString;
    replaceWith.forEach(current => {
      replacestr = replacestr.replace(searchValue, current)
    })
    return replacestr;
  } else {
    return originalString && originalString.replace(searchValue, replaceWith)
  }
}

export const getStartEndPagination = (size, number, total) => {
  return {
    start: size * (number - 1) + 1,
    end: size * number > total ? total : size * number,
  }
}

/**
 * @desc returns true if all entitlement value are true/false(checkFor) else return false
 * @param {Array} entitlements: an array of entitlement object
 * @param {Boolean} checkFor: check all the entitlement value for the given Boolean - true or false
 * @returns boolean
 */
export const checkAllEntitlementValue = (entitlements, checkFor) => {
  if (typeof entitlements === 'undefined') {
    /**
     * This case is for protection when the entitlement call fails.
     * It will make sure that we have the least visibility by default/error 
     */
    return checkFor ? false : true;
  }
  return Object.prototype.toString.call(entitlements) === '[object Object]' ?
    Object.values(entitlements).every(val => val === checkFor) : false;
}

export const UpdatePageUrlWithoutRefresh = (url, history) => {
  history.replace({
    pathname: url,
    state: { mode: history.location?.state?.mode },
  });
}

export const checkIfAllQueryStringsExist = (url, qsCollection) => {
  let isContain = false;
  for (var i = 0; i < qsCollection.length; i++) {
    if (url.indexOf(qsCollection[i]) !== -1) {
      isContain = true;
    }
  }
  return isContain;
}

/**
 * @description - Check both arrays contains same element
 * @param - array1, array 2
 * @returns - boolean
*/
export const findCommonElements = (arr1, arr2) => {
  return arr1.some(item => arr2.includes(item))
}

export const checkIsGivenValueIsAArray = (value) => {
  if (value && Array.isArray(value))
    return true;

  return false
}

export const checkIsGivenValueIsAObj = (value) => {
  if (typeof value === 'object')
    return true;

  return false
}

/**
 * @desc check whether an object is empty or not
 * @param {Object} obj ==> object to be checked for emptiness
 * @returns {Boolean} true if isEmpty else false
 */
export const isObjEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

export const getUserFullnameFromToken = () => {
  const { accessToken } = getFromLocalStorageAsJSON("okta-token-storage")?.accessToken || {};
  /**
   * we can ignore the below deprecation warning as it is only for the node env. Please have a look at the link below
   * https://stackoverflow.com/questions/68849233/convert-a-string-to-base64-in-javascript-btoa-and-atob-are-deprecated
   */
  const parsedAccessToken = JSON.parse(atob(accessToken.split('.')[1]));
  const { fullname } = parsedAccessToken;
  return fullname;
}

export const getUserInitials = (fullname, isFirstnameLastnameInitialsOnly) => {
  let initials;
  if (isFirstnameLastnameInitialsOnly) {
    const splittedFullname = fullname.split(' ');
    initials = splittedFullname[0].substring(0, 1).toUpperCase();

    if (splittedFullname.length > 1) {
      initials += splittedFullname[splittedFullname.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  return fullname.split(" ").map((n) => n[0].toUpperCase()).join("");
}

export const scrollElementToPosition = (container, element, behavior = 'smooth') => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const topPosition = elementRect.top - containerRect.top + container.scrollTop;

  container.scrollTo({
    top: topPosition,
    behavior
  })
}

export const scrollWindowToPosition = (container, element, behavior = 'smooth') => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const topPosition = elementRect.top - containerRect.top;

  window.scrollTo({
    top: topPosition,
    behavior
  })
}

export const scrollWindowToBottom = (behavior = 'smooth') => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior
  })
}


export const mapMetadataToDoc = (kpId, metadata) => {
  const doc = {
    kpId,
    title: metadata.title,
    materialType: [
      metadata.contentType
    ],
    fileName: metadata.filename,
    attachmentFileType: metadata.filename.slice((metadata.filename.lastIndexOf(".") - 1 >>> 0) + 2),
    description: metadata.description,
    paRecommended: metadata.isPARecommended,
    baseURL: metadata.url + "/" + metadata.s3Object + "/",
    frameworkSlides: metadata?.slidesTagged || [],
    slides: metadata.previews.map(p => {
      return {
        relevance: 0,
        slideNumber: p.pageNumber,
        imageRef: p.medium.hqUrl,
        htmlUrl: p.html.hqUrl
      }
    }),
    currentSlideNumber: 0
  }

  return doc;
};

export const docvizOtherProps = () => {
  const apiConfig = {
    "key": CONFIG.DOCVIZ_X_API_KEY,
    "token": () => authService.getAccessToken(),
    "env": CONFIG.SERVER_URL
  };

  // const { X_API_KEY, API_URL: { FRAMEWORK_POST_API } } = CONFIG;

  const LOCAL_CONFIG = {
    // isSmallDevice: false,
    // searchApi: {
    //   domain: CONFIG.ENRICH_API_DOMAIN,
    //   key: CONFIG.ENRICH_API_DOMAIN_KEY,
    // },
    // frameworkApi: {
    //   url: FRAMEWORK_POST_API(),
    //   api_key: X_API_KEY,
    // },
    // showPreviewOption: true,
    apiConfig,
    // showActions: true,
    // showTabs: false,
    // truncatedBody: true,
    entitled: true
  };
  return LOCAL_CONFIG;
}

export const handleCopy = async (ref) => {
  try {
    if (document.execCommand('copy') && navigator.userAgent.search("Firefox") !== -1) {
      const range = document.createRange();
      range.selectNodeContents(ref.current);
      const selectedAnswer = window.getSelection();
      selectedAnswer.removeAllRanges();
      selectedAnswer.addRange(range);
      document.execCommand('copy');
      selectedAnswer.removeAllRanges();
    } else {
      const clipboardText = ref.current.outerHTML;
      const data = [new window.ClipboardItem({ 'text/html': new Blob([clipboardText], { type: 'text/html' }) })];
      navigator.clipboard.write(data);
    }
  } catch (err) {
    console.log("Error copying to clipboard")
  }
}


/**
 * @desc this function toggles isOwner property to specific element and set false for all other
 * @param tabs as object of objects
 * @param pillId as string
 * @returns object with new isOwner property updated
 */
export const setOwnerForAllTabs = (tabs, pillId) => {
  const copy = copyObject(tabs);
  copy && Object.keys(copy).forEach(tab => {
    copy[tab] && Object.keys(copy[tab]).forEach(id => {
      if (id === pillId) {
        copy[tab][id].isOwner = !copy[tab][id].isOwner;
      }
      else {
        copy[tab][id].isOwner = false;
      }
    });
  });
  return copy;
};

/**
 * Function used to return list of polyhierarchi data
 * @param {*} selectedTaxonomyId 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
export const getPolyHierarchiData = (selectedTaxonomyId, taxonomyTypeData) => {
  let obj = {
    isPolyHierarchiDataPresent: false,
    polyHierarchiIdsList: []
  }
  try {
    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(selectedTaxonomyId);
    if (polyHierarchiResponseList.length > 0) {
      obj.isPolyHierarchiDataPresent = true;
      obj.polyHierarchiIdsList = updatedPolyHierarchalList(polyHierarchiResponseList, taxonomyTypeData);
    }
  } catch (error) {
    console.log('Error in getPolyHierarchiData function ', error)
  }
  return obj;
}

export const isCurrentAPolyGuid = (currentId) => {
  const currentState = store?.getState()
  const NEW_POLY_HIERARCHICAL = currentState?.polyhierarchy?.polyhierarchyData?.value;
  const { POLY_HIERARCHICAL, IS_OLD_POLY_CONFIG } = CONFIG;
  let isPolyGuid = false
  if (NEW_POLY_HIERARCHICAL?.length > 0) {
    let polyData = JSON.parse(JSON.stringify(IS_OLD_POLY_CONFIG ? POLY_HIERARCHICAL : NEW_POLY_HIERARCHICAL));
    if (IS_OLD_POLY_CONFIG) {
      let obj = {}
      polyData.map(polyOuterArray => {
        polyOuterArray.map(polyInnerItems => {
          const { id } = polyInnerItems;
          obj[id] = id;
          return polyInnerItems;
        })
        return polyOuterArray;
      });
      if (obj && obj[currentId]) {
        isPolyGuid = true;
      }

    } else {
      polyData.map(obj => {
        const { id } = obj;
        if (id === currentId) {
          isPolyGuid = true;
        }
      });
    }
  }

  return isPolyGuid;
}

export const getTagsInfoIconDetailsifAny = (item, infoConfig) => {
  let infoData = null;

  try {
    if (infoConfig && checkIsGivenValueIsAArray(infoConfig) && checkIsGivenValueIsAObj(item)) {
      infoConfig.map(infoItem => {
        const { infoIconKeyName, infoIconValueName } = infoItem;
        if (item[infoIconKeyName] === infoIconValueName) {
          infoData = {
            ...infoItem,
            isTagged: true
          }
        }
        return infoItem;
      })
    }
  } catch (error) {
    console.log('Error in getTagsInfoIconDetailsifAny function ', error)
  }

  return infoData;
}

export const getHighLighterText = (searchWord, textToHighlight) => {
  let parts = [];
  try {
    let text_escaped = searchWord.replace(/([.^$|*+?()[\]{}\\-])/g, "\\$1");
    parts = textToHighlight.split(new RegExp(`(${text_escaped})`, 'gi'));
  } catch (error) {
    console.log('Error in getHighLighterText function ', error);
  }
  return parts;
}

export const polyHMaxLimitValidation = (polyIdsList, maxLimit, singleID) => {
  try {
    let updatedPolyIdsList = updateCorrectPolyHIdsData(polyIdsList);

    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(singleID);

    if (singleID && polyHierarchiResponseList.length > 0 && updatedPolyIdsList.length <= maxLimit && isElementPresentInArrayOfArray(updatedPolyIdsList, polyHierarchiResponseList[0].id)) {
      return false;
    } else if (singleID && polyHierarchiResponseList === 0 && updatedPolyIdsList.length < maxLimit) {
      return false;
    } else if (updatedPolyIdsList.length < maxLimit) {
      return false;
    }
  } catch (error) {
    console.log('Error in polyHMaxLimitValidation function ', error)
  }

  return true;
}

export const createTaxonomyDataPath = (data) => {
  let updatedObj = {};

  try {
    if (checkIsGivenValueIsAArray(data)) {
      updateItemNested(data, updatedObj);
    }
  } catch (error) {
    console.log('Error in createTaxonomyDataPath function ', error);
  }
  return updatedObj;

}

/**
 * @desc: filter the region data by global ID and name
 * @param: data - array of objects
 * @returns: filtered data - array of objects 
 * */

export const filterRegionsDataByGlobal = (data, keyname) => {
  const globalGUID = CONFIG.TAXONOMY_IDS['GLOBAL_REGION'].toLowerCase();
  const newarr = data.filter(eachnode => (eachnode[keyname] !== globalGUID));
  return newarr;
}

/**
 * @desc: filter the region data by global ID and name
 * @param: data - array of objects
 * @returns: filtered data - array of objects 
 * */

export const filterInternalOfficeDataByGlobal = (data, keyname) => {
  const globalGUID = CONFIG.TAXONOMY_IDS['GLOBAL_INTERNAL_OFFICE'].toLowerCase();
  const newarr = data.filter(eachnode => (eachnode[keyname] !== globalGUID));
  return newarr;
}

/**
 * Function used to check if selectedTaxonomyId is a part of
 * polyherarchi or not
 * @param {*} selectedTaxonomyId 
 * @returns 
 */

const checkIfIdIsPolyHierarchcal = (selectedTaxonomyId) => {
  const currentState = store?.getState()
  const NEW_POLY_HIERARCHICAL = currentState?.polyhierarchy?.polyhierarchyData?.value;
  const { POLY_HIERARCHICAL, IS_OLD_POLY_CONFIG } = CONFIG;
  let polyHierarchicalIdList = [];
  try {
    if (IS_OLD_POLY_CONFIG) {
      for (let i = 0; i < POLY_HIERARCHICAL.length; i++) {
        let hierarchicalData = POLY_HIERARCHICAL[i];
        let hierarchicalDataFound = false;
        for (let j = 0; j < hierarchicalData.length; j++) {
          let hierarchicalObj = hierarchicalData[j];
          const { id } = hierarchicalObj;
          if (id === selectedTaxonomyId) {
            polyHierarchicalIdList = JSON.parse(JSON.stringify(hierarchicalData));
            hierarchicalDataFound = true;
            break;
          }
          if (hierarchicalDataFound) {
            break;
          }
        }
      }
    } else {
      if (NEW_POLY_HIERARCHICAL?.length > 0) {
        for (let i = 0; i < NEW_POLY_HIERARCHICAL.length; i++) {
          let hierarchicalData = NEW_POLY_HIERARCHICAL[i];
          let hierarchicalDataFound = false;
          const { id, polyhierarchyterms, type } = hierarchicalData;

          if (id === selectedTaxonomyId) {
            polyHierarchicalIdList = JSON.parse(JSON.stringify([{ id, type }, ...polyhierarchyterms]));
            hierarchicalDataFound = true;
            break;
          }

          if (hierarchicalDataFound) {
            break;
          }
        }
      }
    }
  } catch (error) {
    console.log('Error in checkIfIdIsPolyHierarchcal function ', error)
  }
  return polyHierarchicalIdList
}

/**
 * Update polyhierarchi list of data so that we can add them in taxonomy data
 * @param {*} selectedTaxonomyId 
 * @param {*} listOfPolyHierarchiIDs 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
const updatedPolyHierarchalList = (listOfPolyHierarchiIDs, taxonomyTypeData) => {
  let updatedPolyData = [];
  try {
    listOfPolyHierarchiIDs.map(item => {
      let obj = getPolyIdDataFromTaxonomyList(item.id, taxonomyTypeData[item.type])
      if (obj) {
        obj.type = item.type;
      }
      updatedPolyData.push(obj)
      return item;
    })
  } catch (error) {
    console.log('Error in updatedPolyHierarchalList function ', error)
  }
  return updatedPolyData;
}
const isElementPresentInArrayOfArray = (arrayOfArray, elemTofind) => {

  let isElementExist = false;
  arrayOfArray?.forEach((innerArray) => {
    if (innerArray && innerArray.length > 0) {
      innerArray.forEach((element) => {
        if (element?.id === elemTofind) {
          isElementExist = true;
        }
      });
    }
  });
  return isElementExist;
}

const updateCorrectPolyHIdsData = (polyIdsList) => {
  let updatedPolyIdsList = [];

  for (let i = 0; i < polyIdsList.length; i++) {
    const elemTofind = polyIdsList[i];

    if (!isElementPresentInArrayOfArray(updatedPolyIdsList, elemTofind)) {
      let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(elemTofind);
      if (polyHierarchiResponseList.length > 0) {
        updatedPolyIdsList.push(polyHierarchiResponseList);
      } else {
        updatedPolyIdsList.push([elemTofind]);
      }
    }
  }

  return updatedPolyIdsList;
}

const updateItemNested = (arr, obj) => {
  try {
    if (arr.length === 0) return;

    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      const { Id, Children, Path } = item;
      if (!obj[Id]) {
        obj[Id] = getUpdatePath(Path);
      }
      if (Children && Children.length > 0) {
        updateItemNested(Children, obj);
      }
    }
  } catch (error) {
    console.log('Error in updateItemNested function ', error)
  }
}

/**
 * Funcito used to return obj for all guids which are present in polyherarchi
 * @param {*} polyId 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
const getPolyIdDataFromTaxonomyList = (polyId, taxonomyTypeData) => {
  return findItemNested(taxonomyTypeData, polyId, "Children");
}

const getUpdatePath = (path) => {
  let responsePathArray = [];
  try {
    if (path) {
      let pathArray = path.split('/');

      if (pathArray && pathArray.length > 0) {
        let arrayIndex = getPathIndex(pathArray);

        if (arrayIndex) {
          for (let i = arrayIndex + 1; i < pathArray.length; i++) {
            if (pathArray[i]) {
              responsePathArray.push(pathArray[i])
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('Error in getUpdatePath function ', error)
  }

  return responsePathArray;
}

const findItemNested = (arr, itemId, nestingKey) => (
  arr?.reduce((a, item) => {
    if (a) return a;
    if (item.Id === itemId) return item;
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
  }, null)
);

const getPathIndex = (pathArray) => {
  let arrayIndex = null;
  try {
    const {
      TBDB_IDS: {
        FPA,
        IPA,
        BCG_INTERNAL
      }
    } = CONFIG;

    let functionalPracticeArea = {
      [FPA]: FPA,
      [IPA]: IPA,
      [BCG_INTERNAL]: BCG_INTERNAL
    };

    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i] === functionalPracticeArea[pathArray[i]]) {
        arrayIndex = i;
        break;
      }
    }
  } catch (error) {
    console.log('Error in getPathIndex function ', error);
  }

  return arrayIndex;
}


/**
 * @description this function creates copy of an object
 * @param obj as an object 
 * @returns object copy
 */
export const copyObject = (obj) => {
  if (!!obj && typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};