import React from "react";
import Image from "components/shared/Image";
import PropTypes from "prop-types";

import chatIcon from "assets/images/chat_icon.svg";

import "./SideBar.scss";

export const SideBar = ({ onClickActionHandler }) => {

    return (
        <div className="sidebar" onClick={onClickActionHandler}>
            <div className="sidebar__wrap">
                <div className="sidebar__image">
                    <Image src={chatIcon} alt="chatIcon" />
                </div>
            </div>
        </div>
    );
};


SideBar.prototypes = {
    onClickActionHandler: PropTypes.func
}

SideBar.defaultProps = {
    onClickActionHandler: () => { }
}
