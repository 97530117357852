const LABELS = {
  CHAT_EMPTY_TITLE: "Great!",
  CHAT_EMPTY_SUBTITLE: "Let's get started...",
  INPUT_EMPTY_HEADING: "Ask any question or select from above prompts",
  INPUT_HEADING: "Ask any question",
  CITATIONS_HEADING: "Citations:",
  CLOSE: "Close",
  TAXONOMY: {
    // TOPIC_LABEL:
    //   "Which PA/Topic Page would you like to display this Bundle on?",
    // TOPIC_PLACEHOLDER: "Please add PA/Topic Page",
    // TOPIC_MODAL_HEADING:
    //   "Which PA/Topic Page would you like to display this Bundle on?",

    // IPA_FPA_LABEL: "Select PA Sectors/Topics",
    // IPA_FPA_TIPTEXT: "Respective Sector/Topic owners are notified of changes to this Knowledge Bundle",
    // IPA_FPA_PLACEHOLDER: "Select PA Sectors/Topics",
    // IPA_FPA_MODAL_HEADING: "Select PA Sectors/Topics",
    // IPA_FPA_MODAL_SEARCH_PLACEHOLDER: "Search Practice area",

    // SUBJECT_LABEL: "Search and Discovery Tags",
    // SUBJECT_PLACEHOLDER: "Eg: Industrial Goods",
    // SUBJECT_MODAL_HEADING: "Search and Discovery Tags",
    // SEARCH: "Search",
    // SEARCH_SUBJECT_PLACEHOLDER: "Search Subject",
    // SECTION_ADMIN_HEADING: "Administrative & Reporting Tags",
    // TYPESEARCH_PLACEHOLDER: "Search tags",
    // AUTOTAGGED_MESSAGE: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case number.",
    // AUTOTAGGED_MESSAGE_CASES: "Tag added as per Client and Client Business Unit.",
    // NORESULT_TYPEAHEAD_HEADING: "There are no relevant business tags available for this keyword!",
    TOOLTIPS: {
      IPA_TIP_MESSAGE: "IPA tagging is not available as Internal KP type is selected",
      FPA_TIP_MESSAGE: "FPA tagging is not available as Internal KP type is selected",
      POLY_HIERARCHI_CUSTODIAN_MSG: "Shared topic cannot be selected as Custodian, please choose a non-shared topic.",
      POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG: "Shared topic, additional tag will be auto applied/removed.",
      TAG_SPECIFIC_TO_GEO_KNOWLEDGE_TEAM: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case number.",
      TAG_SPECIFIC_FOR_CLIENT_AND_BUSINESS_UNIT: "Tag added as per Client and Client Business Unit.",
      TAG_SPECIFIC_TO_GEO_AUTO_TAG: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case office.",
      CUSTODIAN_POLY_MSG: "Shared Topic;",
      CUSTODIAN_POLY_MSG_2: "Shared Topic",
      TAG_SPECIFIC_TO_REVENUE_ALLOCATION_TAG: "Tag added as per Overlay Topic revenue allocation",
      TAG_SPECIFIC_TO_BCG_TRANSFORM_TAG: "Tag added as per BCG Transform Revenue flag value",
      TAG_SPECIFIC_TO_BCG_PIPE_TAG: "Tag added as per BCG PIPE Revenue flag value"
    }
  },
  ADMIN_SELECTPA_HEADING: "Associated PA/Topic/Sectors (Max 6 tags)",
  SUBJECT_TAXONOMY_HEADING: "Search and Discovery Tags",
  IPA_FPA_MODAL_SEARCH_PLACEHOLDER: "Search Practice area",
  METADATA: {
    MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG_TITLE: "Limit reached",
  },
  ALT_TEXTS: {
    ENTER_ICON: "Click here on Enter Icon on search the Material",
    DELETE_ICON: "Delete Icon",
    ENTER_ICON_URL: "Enter Additional Url",
    ADD_URL: "Add url",
    CROSS: "Cross",
    ADD_CASE_CODE: "Add case code",
    SEARCH_ICON: "Search",
    MORE_OPTIONS: "More Options",
    SUGGESTION_LIST_INFOTEXT: "Couldn't find what you were looking for? Try refining your search keyword",
    LOCATION_ICON: "Case Office Location",
    KNOWLEDGE_ASSETS: "Knowledge Assets",
    NO_KNOWLEDGE_ASSETS: "No Knowledge Assets",
    NEXT_STEP: "Proceed to Step 3 - Select Tags",
    CANCEL_MODAL_ALT: "Cancel the Image Picker modal"
  },
  THUMBSUPDOWN: {
    THANKYOU: "Thank you!",
    THANKYOU_PARA: "This will help us improve the content quality.",
    HEADING: "How would you rate your content quality experience on this collection?",
    REASON_1: "Inaccurate response",
    REASON_2: "Irrelevant response",
    REASON_3: "Outdated content",
    REASON_4: "Others",
    TEXTAREA_PLACEHOLDER: "Please provide the reason for your dissatisfaction",
    SUBMIT: "Submit",
    HEADING_KP: "Rate this Content",
    HEADING_FEEDBACK: "Is this helpful?",
    THUMBSUP: "thumbs up",
    THUMBSDOWN: "thumbs down"
  },
  NEW_CHAT: "New Chat",
  THOUGHT_CHAIN_HEADING: "Thought chain",
  ADD_TO_COLLECTION: "Add to collection",
  CHAT_LIMIT_REACHED_MSG: "You've reached the conversation limit for this session. Please create a new session",
  DOWNLOAD: "Download",
  CHAT_HISTORY: "Chat History",
  FOLLOWUP_QUESTIONS: "Follow-up questions",
  THOUGHT_CHAIN_SEARCHED_FOR: "Searched For:",
  THOUGHT_CHAIN_DOCUMENT_RETRIEVED: "Documents Retrieved From Knowledge:",
  MAX_SESSION_LIMIT_REACHED: "We currently support maximum of '<value>' chat sessions, per user.",
  MAX_SESSION_LIMIT_REACHED_1: "Please delete any of the old chat sessions to continue!",
  THOUGHT_CHAIN_DOCUMENT_SOURCED: "Sources for Generating Response:",
  DOCVIZ_PREVIEW_NOT_AVAILABLE: "Docviz Preview currently not available !",
  FILE_EXTENSION: {
    PPTX: 'pptx'
  },
  INPUT_DISCLAIMER_TEXT: "Do not enter any Client sensitive Information on Chat",
  PROPOSAL_ACCELERATOR: {
    SESSION: 'Session',
    CASE_NUMBER_PLACEHOLDER: "Add case number",
    ADD_CASE_CODE: "Add case code",
    DUPLICATE_CASE: "already exists in related case number list",
    CROSS: "Cross",
    DELETE: 'delete',
    INVALIDCASE: "Please enter the valid Case Number.",
  }
};
export default LABELS;
