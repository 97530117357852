import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import AddIcon from "assets/images/add/add-white.svg";
import crossIcon from "assets/images/cross-icon.svg";
import warningIcon from "assets/images/warning/warning.svg";
import { Tooltip } from "components/shared/Tooltip";
import { actions as caseCodeActions } from "redux/actions/casecode/casecode_action";
import { selectors as caseCodeSelectors } from "redux/reducers/casecode/casecode_reducer";
import CONFIG from "./CaseNumberSelector.config";
import LABELS from "labels";
import GLOBAL_CONSTANTS from "globalConstants";
import GLOBAL_CONFIG from 'config';
import { v4 as uuidv4 } from 'uuid';
// scss
import "./CaseNumberSelector.scss";

class CaseNumberSelectorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseCodeValue: "",
      selectedCaseCode: props.preFillData ? props.preFillData : [],
      error: false,
      errorText: ""
    };
  }

  componentDidUpdate = (prevProps) => {
    const { errorStatus, preFillData } = this.props;
    if (prevProps?.caseData !== this.props?.caseData) {
      this.setCaseCodeState();
    } else {
      if (prevProps.errorStatus !== this.props.errorStatus) {
        if (errorStatus === CONFIG.INVALID_CASE_TEXT || errorStatus === CONFIG.NO_CONTENT_TEXT) {
          this.setErrorState();
        }
      }
    }
    if (prevProps.radioOption !== this.props.radioOption) {
      const { caseCodeActions: { resetErrorState } } = this.props;
      resetErrorState();
      this.resetComponentState();
    }

    if (preFillData && preFillData !== prevProps.preFillData) {
      this.setState({
        selectedCaseCode: preFillData
      });
    }
  }

  resetComponentState = () => {
    this.setState({
      error: false,
      errorText: "",
      caseCodeValue: ""
    });
    this.handleErrorStateChange(false);
  }

  setErrorState = () => {
    const { PROPOSAL_ACCELERATOR: { INVALIDCASE } } = LABELS;
    this.setState({
      error: true,
      errorText: INVALIDCASE,
    });

    this.handleErrorStateChange(true);
  }

  setCaseCodeState = () => {
    const { caseCodeValue, selectedCaseCode } = this.state;
    const { onChange, caseData } = this.props;
    this.setState({
      selectedCaseCode: [
        ...selectedCaseCode,
        caseCodeValue
      ],
      error: false,
      errorText: "",
      caseCodeValue: ""
    }, () => {
      const { selectedCaseCode } = this.state;
      onChange(caseData, selectedCaseCode);
    });
  }

  handleOnChange = (e) => {
    this.setState({
      caseCodeValue: e.target.value
    });
  }
  handleErrorStateChange = (errorState) => {
    if (typeof this.props.onErrorStateChange === 'function') {
      this.props.onErrorStateChange(errorState);
    }
  }

  handleKeyDown = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  }

  handleCaseCodeSelect = () => {
    const { caseCodeValue, selectedCaseCode } = this.state;
    const { caseCodeActions: { getCaseCodeData }, isLoading } = this.props;
    const { PROPOSAL_ACCELERATOR: { DUPLICATE_CASE } } = LABELS;
    if (selectedCaseCode.indexOf(caseCodeValue) !== -1) {
      this.setState({
        error: true,
        errorText: `${caseCodeValue} ${DUPLICATE_CASE}`,
      });
    } else if (!isLoading) {
      getCaseCodeData(caseCodeValue);
    }
  }

  deleteSelectedCaseCode = (item) => {
    const { caseData, onChange } = this.props;
    const { PROPOSAL_ACCELERATOR: { DELETE } } = LABELS;
    this.setState({
      selectedCaseCode: this.state.selectedCaseCode.filter(function (caseCode) {
        return caseCode !== item
      })
    }, () => {
      const { selectedCaseCode } = this.state;
      onChange(caseData, selectedCaseCode, DELETE);
    });
  }
  getAssociatedCasesDetails = () => {
    const { associatedCases } = this.props;
    let temp = {};
    const {
      UI_URL: {
        CP_CONSUMPTION_NEW
      }
    } = GLOBAL_CONFIG;
    const { ASSOCIATED_CASES_NO_TITLE, ASSOCIATED_CASES_NO_DETAILS } = LABELS;
    associatedCases.forEach(({ caseNumber, id, sanitizedCaseTitle }) => {
      temp = {
        ...temp,
        [caseNumber]: {
          url: !!id ? CP_CONSUMPTION_NEW(id) : '',
          title: !!id ? (!!sanitizedCaseTitle ? sanitizedCaseTitle : ASSOCIATED_CASES_NO_TITLE) : ASSOCIATED_CASES_NO_DETAILS
        }
      }
    });
    return temp;
  }

  render() {
    const { radioOption, className, tooltipHtml, limit, associatedCases } = this.props;
    const { caseCodeValue, selectedCaseCode, error, errorText } = this.state;
    const {
      PROPOSAL_ACCELERATOR: { CASE_NUMBER_PLACEHOLDER, ADD_CASE_CODE }
    } = LABELS;
    const casesData = this.getAssociatedCasesDetails();
    return (
      <div className={`case__block ${className ? className : " "} ${error ? "customhighlight case__error--border" : " "}`}>
        <label className={`tooltip__label`}>{"Case Number"}</label>
        {tooltipHtml && (
          <Tooltip id="case_selector">{tooltipHtml}</Tooltip>
        )}
        <div className={`case__inputwithicon`}>
          <div className="case__input--block">
            {!!selectedCaseCode.length && selectedCaseCode.map((item, index) => (
              <div key={uuidv4()} className="case__selecteditem">
                {casesData[item]?.url ? (
                  <Tooltip
                    id={index + "case-title"}
                    position="top"
                    icon={
                      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                      <a
                        href={casesData[item]?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="case__link"
                      >

                        {item}
                      </a>
                    }
                  >
                    {casesData[item]?.title}
                  </Tooltip>
                ) : (
                  associatedCases.length ?
                    <Tooltip
                      id={index + "case-title"}
                      position="top"
                      icon={
                        <span className="case__disabled">
                          {item}
                        </span>
                      }
                    >
                      {casesData[item]?.title}
                    </Tooltip>
                    :
                    <span>{item}</span>
                )}


                <Image
                  className="case__selected__crossicon"
                  src={crossIcon}
                  alt={LABELS.PROPOSAL_ACCELERATOR.CROSS}
                  onClick={() => this.deleteSelectedCaseCode(item)}
                />
              </div>
            ))}
            {selectedCaseCode.length < limit &&
              <input type="text"
                placeholder={CASE_NUMBER_PLACEHOLDER}
                className="case__input" value={caseCodeValue}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleOnChange}
              />
            }
          </div>
          {selectedCaseCode.length < limit &&
            <div className="case__btn--black" onClick={this.handleCaseCodeSelect}>
              <Image src={AddIcon} alt={ADD_CASE_CODE} className="case__plus-icon" />
            </div>
          }
        </div>
        {error ? <div className="case__error">
          <Image
            src={warningIcon}
            alt="icon warning"
            className="case__error--icon"
          />
          <span className="case__errormsg">{errorText}</span>
        </div> : ""}
      </div>
    );
  }
}

CaseNumberSelectorContainer.defaultProps = {
  preFillData: [],
  onChange: () => { },
  caseData: {},
  errorStatus: "",
  radioOption: false,
  isLoading: false,
  limit: 1000,
  associatedCases: []
};

CaseNumberSelectorContainer.propTypes = {
  preFillData: PropTypes.array,
  radioOption: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errorStatus: PropTypes.string,
  caseData: PropTypes.object,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  associatedCases: PropTypes.array
};

const mapStateToProps = (state) => ({
  caseData: caseCodeSelectors.getCaseData(state),
  errorStatus: caseCodeSelectors.getErrorStatus(state),
  isLoading: caseCodeSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  caseCodeActions: bindActionCreators({ ...caseCodeActions }, dispatch),
});

export { CaseNumberSelectorContainer };

export const CaseNumberSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseNumberSelectorContainer);