import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { USER_CHAT_SESSION } from "redux/constants";

import {
    getActiveUserChatSessionFailure,
    getActiveUserChatSessionSuccess,
    getActiveUserChatSessionPending,
    createUserChatSessionFailure,
    createUserChatSessionSuccess,
    createUserChatSessionPending,
    getChatHistorySuccess,
    getChatHistoryPending,
    getChatHistoryFailure,
    getChatSessionHistoryFailure,
    getChatSessionHistoryPending,
    getChatSessionHistorySuccess,
    clearChatHistory,
    deleteChatSessionFailure,
    deleteChatSessionPending,
    deleteChatSessionSuccess,
    updateUserChatSessionSuccess,
    setHistoryChatSectionDisabled
} from "./userchatsession.actions";

import {
    clearSlidesData
} from "../slides/slides.actions";

const {
    GET_ACTIVE_USER_CHAT_SESSION,
    CREATE_USER_CHAT_SESSION,
    GET_CHAT_HISTORY,
    GET_CHAT_SESSION_HISTORY,
    DELETE_CHAT_SESSION
} = USER_CHAT_SESSION;

const {
    X_API_KEY,
    API_URL: {
        GET_ACTIVE_CHAT_SESSION_ID,
        CREATE_CHAT_SESSION_ID,
        DELETE_CHAT_SESSION_ID
    }
} = CONFIG;

export function* getActiveUserChatSession() {
    try {
        yield put(getActiveUserChatSessionPending());
        const endpoint = GET_ACTIVE_CHAT_SESSION_ID();
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getActiveUserChatSessionSuccess(res));
    } catch (error) {
        console.error("error: ", error)
        yield put(getActiveUserChatSessionFailure(error?.message));
    }
}

export function* createUserChatSession(action) {
    const { payload: {
        data,
    } } = action;

    try {
        yield put(createUserChatSessionPending());
        const endpoint = CREATE_CHAT_SESSION_ID;
        let res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        if (data === 'new') {
            yield put(clearChatHistory());
            yield put(createUserChatSessionSuccess(res));
            yield call(getChatSessionHistory, {});
        }

        return res;
    } catch (error) {
        console.error("error: ", error)
        yield put(createUserChatSessionFailure(error?.message));
    }
}

export function* getChatHistory(action) {
    try {
        const { X_API_KEY, API_URL: { GET_CHAT_HISTORY } } = CONFIG;
        const { payload: { sessionId } } = action;
        yield put(getChatHistoryPending());
        yield put(clearSlidesData());

        const endpoint = GET_CHAT_HISTORY(sessionId);
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getChatHistorySuccess(res));
        yield put(setHistoryChatSectionDisabled(true));
    } catch (error) {
        console.error("error: ", error)
        yield put(getChatHistoryFailure(error?.message));
    }
}

export function* deleteChatSession(action) {
    try {
        const { payload: { sessionId, flag } } = action;
        yield put(deleteChatSessionPending());

        const endpoint = DELETE_CHAT_SESSION_ID(sessionId);
        let res = yield call(doAxiosRequest, {
            method: 'delete',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(deleteChatSessionSuccess(res));
        if(flag) {
            yield put(updateUserChatSessionSuccess(''));
            yield put(clearChatHistory());
        }
        yield call(getChatSessionHistory, {});
    } catch (error) {
        console.error("error: ", error)
        yield put(deleteChatSessionFailure(error?.message));
    }
}

export function* getChatSessionHistory() {
    try {
        const { X_API_KEY, API_URL: { GET_CHAT_SESSION_HISTORY } } = CONFIG;
        yield put(getChatSessionHistoryPending());

        const endpoint = GET_CHAT_SESSION_HISTORY;
        let res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: endpoint,
            headers: {
                'x-api-key': X_API_KEY
            }
        });

        yield put(getChatSessionHistorySuccess(res));
    } catch (error) {
        console.error("error: ", error)
        yield put(getChatSessionHistoryFailure(error?.message));
    }
}


export default function* userChatSessionSaga() {
    try {
        yield takeLatest(GET_ACTIVE_USER_CHAT_SESSION, getActiveUserChatSession);
        yield takeLatest(CREATE_USER_CHAT_SESSION, createUserChatSession);
        yield takeLatest(GET_CHAT_HISTORY, getChatHistory);
        yield takeLatest(GET_CHAT_SESSION_HISTORY, getChatSessionHistory);
        yield takeLatest(DELETE_CHAT_SESSION, deleteChatSession);
    } catch (error) {
        yield put(moduleFailure(error, 'User Chat Session Saga'));
    }
}