import {
  RESPONSE_DATA,
  USER_CHAT_SESSION
} from 'redux/constants';
export const name = 'response';
const initialState = {
  loading: false,
  error: '',
  response: [],
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getResponse: (state) => state[name].response
};

const {
  CLEAR_CHAT_HISTORY,
  GET_CHAT_HISTORY_SUCCESS
} = USER_CHAT_SESSION;

const {
  RESPONSE_API_RESULTS_PENDING,
  RESPONSE_API_RESULTS_FAILURE,
  RESPONSE_API_RESULTS_SUCCESS
} = RESPONSE_DATA;

const setChatHistoryState = (state, { data }) => {
  const { chat_history } = data;
  const finalState = chat_history.map(({ id, query, response }) => {
    //TODO once Dev team update the API to provide citations,confidence_text,followup_questions,thoughts in history

    return [
      query?.query,
      {
        chat_id: id,
        citations: response?.citations,
        confidence_text: response?.confidence_text,
        followup_questions: response?.followup_questions,
        thoughts: response?.thoughts,
        response: response?.response
      }
    ];
  })
  return {
    ...state,
    response: finalState,
    loading: false,
    error: ''
  };
}

const clearChatHistory = () => {
  return {
    ...initialState
  }
}

export function reducer(state = initialState, action) {
  const { payload = {} } = action;
  const { response = [], error } = payload;
  switch (action.type) {
    case RESPONSE_API_RESULTS_PENDING:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case RESPONSE_API_RESULTS_FAILURE:
      return {
        ...state,
        error: error,
        loading: false,
      };
    case GET_CHAT_HISTORY_SUCCESS:
      return setChatHistoryState(state, payload);
    case CLEAR_CHAT_HISTORY:
        return clearChatHistory();
    case RESPONSE_API_RESULTS_SUCCESS:
      return {
        ...state,
        response: [
          ...state.response, response
        ],
        loading: false,
        error: ''
      };
    default:
      return state;
  }
}
