// Library Imports
import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { ChatHistory } from "containers/ChatHistory";
import { ThoughtChain } from "components/ThoughtChain";
import { CitationSection } from "components/CitationSection";

// Custom Components
import { Answer, AnswerError } from "components/Answer";
import { ExampleList } from "components/Example";
import { SideBar } from "components/SideBar";
import { LoadingIcon } from "components/Loading/LoadingIcon";
import { Layout } from "containers/Layout";
import { UpdatePageUrlWithoutRefresh, replaceInLabel, scrollElementToPosition, scrollWindowToBottom } from "utils/helpers/helpers";
import { FollowupQuestion } from "components/FollowupQuestion";
import { QuestionInput } from "components/QuestionInput";
import { UserChatMessage } from "components/UserChatMessage";

// Constants, Labels, Configs
import LABELS from "labels";
import { SUGGESTED_QUERY_DATA, RESPONSE_DATA } from "redux/constants";

// Actions
import { getActiveUserChatSession, getChatHistory, getActiveUserChatSessionSuccess, clearChatHistory, setHistoryChatSectionDisabled } from "redux/actions/userchatsession/userchatsession.actions";
import { getSlidesData } from "redux/actions/slides/slides.actions";

// Reducers
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { selectors as suggestedQuerySelector } from "redux/reducers/suggested_query/suggestedquery_reducer";
import { selectors as userChatSessionSelector } from "redux/reducers/userchatsession/userchatsession_reducer";
import { selectors as slidesSelector } from "redux/reducers/slides/slides_reducer";


//Assests
import Image from "components/shared/Image";
import logoIcon from "assets/images/bigglobe.gif";
import globesmall from "assets/images/globe_small.svg";
import warningiconred from "assets/images/warningiconred.svg";

//CSS
import "./ChatAssistant.scss";
import CONFIG from "config";
import { postFeedbackResponse } from "redux/actions/response/response.actions";

const {
    SUGGESTED_QUERY
} = SUGGESTED_QUERY_DATA;

const {
    QUERY_RESPONSE
} = RESPONSE_DATA;

const ChatAssistantContainer = ({
    activeUserChatSessionId,
    history,
    getCurrentUserChatSession,
    postFeedbackOfResponse,
    setCurrentUserChatSession,
    chatHistory,
    getUserChatHistory,
    maxChatSessionLimit,
    chatSessionHistory,
    callSlidesAPI,
    citationSlides,
    citationErrorSlides,
    isChatHistoryLoading,
    isChatHistoryError,
    clearCurrentChatHistory,
    disableChatSessionHistorySection,
    shouldDisableChatHistorySection,
    match: {
        params: { sessionId },
    }
}) => {
    const {
        CHAT_EMPTY_TITLE,
        CHAT_EMPTY_SUBTITLE,
        INPUT_EMPTY_HEADING,
        INPUT_HEADING,
        INPUT_DISCLAIMER_TEXT,
        MAX_SESSION_LIMIT_REACHED,
        MAX_SESSION_LIMIT_REACHED_1,
        CHAT_LIMIT_REACHED_MSG
        // CLOSE
    } = LABELS;
    const { MAX_CITATION } = CONFIG;
    const dispatch = useDispatch();

    const isResponseLoading = useSelector(responseSelector.getLoading);
    const queryResponse = useSelector(responseSelector.getResponse);
    const queryResponseError = useSelector(responseSelector.getError)
    const suggestedQueryResponse = useSelector(suggestedQuerySelector.getResponse);

    const [isOpenThoughtchain, setStateThoughtChain] = useState(false);
    const [isCitationSectionOpen, setCitationSectionOpen] = useState(false);
    const [thoughtChainData, setThoughtChainData] = useState({});
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [selectedCitationSrc, setSelectedCitationSrc] = useState('');
    const [selectedCitationDoc, setSelectedCitationDoc] = useState({});
    const lastQuestionRef = useRef("");
    const chatMessageStreamEnd = useRef(null);
    const chatScrollContainerRef = useRef(null);
    const chatSelectedElementRef = useRef(null);

    const handleScrollToActiveMessage = () => {
        if (selectedAnswer.length && chatSelectedElementRef.current) {
            if (isOpenThoughtchain || isCitationSectionOpen) {
                scrollElementToPosition(chatScrollContainerRef.current, chatSelectedElementRef.current);
            }
            else {
                scrollWindowToBottom();
            }
        }
    }
    const handleThumbsUpData = ({ feedback, comments, chatId }) => {
        postFeedbackOfResponse({
            chatId, comments, feedback
        });
    }

    useEffect(() => {
        dispatch({ type: SUGGESTED_QUERY, payload: {} });
    }, []);

    useEffect(() => {
        // the second condition will help us offset the side effect of the useEffect where activeUserChatSessionId is a dependency
        if (sessionId && sessionId !== activeUserChatSessionId) {
            setCurrentUserChatSession({ chat_session_id: sessionId });
            getUserChatHistory(sessionId);
        }
    }, [sessionId]);

    useEffect(() => {
        if (activeUserChatSessionId) {
            UpdatePageUrlWithoutRefresh('/' + activeUserChatSessionId, history);
        }
    }, [activeUserChatSessionId])

    useEffect(() => {
        if (queryResponse.length) {
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 50)
        }
    }, [queryResponse]);

    useEffect(() => {
        if (shouldDisableChatHistorySection) {
            // when there is no chat history, show the chat history section
            if (!!queryResponse && queryResponse.length === 0) disableChatSessionHistorySection(false);

            let lastThreeChatIds = [];
            // getting the last MAX_CITATAIONS chat ids from the queryResponse
            if (!!queryResponse && queryResponse.length > MAX_CITATION) {
                lastThreeChatIds = queryResponse.slice(-3);
            } else if (!!queryResponse) {
                lastThreeChatIds = [...queryResponse];
            }

            // get all the guids from the lastThreeChatids
            let citationGuidSet = new Set();

            lastThreeChatIds.forEach(chat => {
                let chatResponse = chat[1]; // [[<query>][<response>], ...]
                let chatResponseCitation = chatResponse?.citations;

                chatResponseCitation.forEach(citation => {
                    citationGuidSet.add(citation?.identifier);
                });
            })

            // when there is no citation, show the chat history section
            if (citationGuidSet.size === 0) disableChatSessionHistorySection(false);

            let citationGuidSetFromRedux = new Set();
            Object.keys(citationSlides).forEach((chatId, i) => {
                const chatIdValue = citationSlides[chatId];
                Object.keys(chatIdValue).forEach((item) => citationGuidSetFromRedux.add(item));
            });
            citationGuidSetFromRedux = new Set([...citationGuidSetFromRedux, ...citationErrorSlides]);
            if (citationGuidSet.size === citationGuidSetFromRedux.size && [...citationGuidSet].every(value => citationGuidSetFromRedux.has(value)))
                disableChatSessionHistorySection(false);
        }
    }, [shouldDisableChatHistorySection, citationSlides]);

    useEffect(() => {
        if (!isChatHistoryLoading && isChatHistoryError) {
            setCurrentUserChatSession({ chat_session_id: "" });
            setTimeout(() => {
                UpdatePageUrlWithoutRefresh('/', history);
            }, 2000);
        }
    }, [isChatHistoryLoading, isChatHistoryError]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isResponseLoading]);

    useEffect(() => {
        handleScrollToActiveMessage();
    }, [isOpenThoughtchain, isCitationSectionOpen, selectedAnswer])


    const onNewChatSessionClick = () => {
        // clear chat session
        setCurrentUserChatSession({ chat_session_id: "" });

        // clear chat history
        clearCurrentChatHistory();

        // update the URL without refresh
        UpdatePageUrlWithoutRefresh('/', history);

        // set lastQuestionRef to blank
        lastQuestionRef.current = '';
    }

    const makeApiRequest = (question) => {

        lastQuestionRef.current = question;
        const chatSessionId = sessionId;
        dispatch({ type: QUERY_RESPONSE, payload: { chatSessionId, question: question } });
    };

    const onExampleClicked = (example) => {
        makeApiRequest(example);
    };

    const onThoughtProcessbuttonClick = (answer) => {
        setStateThoughtChain(true);
        setCitationSectionOpen(false);
        setThoughtChainData(answer?.thoughts);
        setSelectedAnswer(answer?.chatId);
    };

    const onCitationViewClick = ({ answer, imgSrc, doc, guid, slidenumber }) => {
        setStateThoughtChain(false);
        setCitationSectionOpen(true);
        setSelectedAnswer(answer?.chatId);
        setSelectedCitationSrc(imgSrc);
        setSelectedCitationDoc({});
        setTimeout(function () {
            setSelectedCitationDoc({ doc, guid, slidenumber });
        }, 1000);
    }

    const handleSidebarClick = () => {
        setStateThoughtChain(false)
        setCitationSectionOpen(false);
    }

    const handleCallSlidesAPI = useCallback(callSlidesAPI, []);
    const MAX_CHAT_LIMIT = chatHistory?.max_chats_per_session || (queryResponse[0] && queryResponse[0][1]?.max_chats_per_session) || 10;
    const isMaxLimitReached = !!(queryResponse?.length && !isResponseLoading && queryResponse.length === MAX_CHAT_LIMIT);

    return (
        <Layout id="chat" layoutClass="layout__chat-assistant">
            <div className={`main ${isOpenThoughtchain || isCitationSectionOpen ? 'opened-detailsec' : ''}`}>
                <SideBar
                    onClickActionHandler={handleSidebarClick}
                />
                {!isOpenThoughtchain && !isCitationSectionOpen &&
                    <ChatHistory
                        onNewChatSessionClick={() => onNewChatSessionClick()}
                        disabled={shouldDisableChatHistorySection}
                    />
                }
                <div className="right-sec" ref={chatScrollContainerRef}>
                    <div className={"container__chat"}>
                        <div className={"chatRoot"}>
                            <div className={"chatContainer"} >
                                <div className={"chatEmptyState"}>
                                    <Image src={logoIcon} alt="logo" />
                                    <h1 className={"chatEmptyStateTitle"}>{CHAT_EMPTY_TITLE}</h1>
                                    <p className={"chatEmptyStateSubtitle"}>{CHAT_EMPTY_SUBTITLE}</p>
                                </div>
                                {(!queryResponse.length && !lastQuestionRef.current) && (
                                    <>
                                        <ExampleList
                                            data={suggestedQueryResponse}
                                            onExampleClicked={onExampleClicked}
                                        />
                                    </>
                                )}
                                {!!sessionId && !isChatHistoryLoading && isChatHistoryError &&
                                    <div className="unabletoload">Unable to load conversation {sessionId}</div>
                                }
                                {(!!queryResponse.length || isResponseLoading) && (<div className={"chatMessageStream"}>
                                    {queryResponse.map((answer, index) => (
                                        <div key={index}>
                                            <UserChatMessage message={answer[0]} />
                                            <div className={"chatMessageGpt"} ref={(selectedAnswer === answer[1].chat_id ? chatSelectedElementRef : null)}>
                                                <Answer
                                                    key={index}
                                                    counter={index + 1}
                                                    maxlimit={MAX_CHAT_LIMIT}
                                                    answer={answer[1]}
                                                    onThoughtProcessClicked={(answer) => onThoughtProcessbuttonClick(answer)}
                                                    onCitationViewClick={onCitationViewClick}
                                                    callSlidesAPI={handleCallSlidesAPI}
                                                    citationSlides={citationSlides}
                                                    selected={selectedAnswer}
                                                    isOpenThoughtchain={isOpenThoughtchain}
                                                    handleThumbsUpData={handleThumbsUpData}
                                                    smallPreviewCitations={queryResponse.length - MAX_CITATION <= index && queryResponse.length >= index}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {isResponseLoading && (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} />
                                            <div className={"chatMessageGptMinWidth"}>
                                                <LoadingIcon />
                                            </div>
                                        </>
                                    )}
                                    {queryResponseError ? (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} />
                                            <div className={"chatMessageGptMinWidth"}>
                                                <AnswerError error={queryResponseError.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                            </div>
                                        </>
                                    ) : null}
                                    <div ref={chatMessageStreamEnd} />
                                    {!isResponseLoading && !isMaxLimitReached &&
                                        <FollowupQuestion
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            queryResponse={queryResponse}
                                        />
                                    }
                                    {isMaxLimitReached &&
                                        <div className="maxchatlimit">
                                            <span className="maxchatlimit__inner">
                                                <Image src={warningiconred} alt="warning" /> {CHAT_LIMIT_REACHED_MSG}
                                            </span>
                                        </div>
                                    }
                                </div>
                                )}
                                <div className="chatInput__wrap">
                                    <div className={`chatInput__inner ${isMaxLimitReached ? " chatInput__inner--disabled" : ""}`}>
                                        <div className="chatInput__heading"><Image src={globesmall} alt="logo" />{!lastQuestionRef.current ? INPUT_EMPTY_HEADING : INPUT_HEADING}</div>
                                        <div className={"chatInput"}>
                                            <QuestionInput
                                                clearOnSend
                                                placeholder="Type a new question (e.g. Why BCG in Oil & Gas?)"
                                                disabled={isResponseLoading || isMaxLimitReached}
                                                onSend={question => makeApiRequest(question)}
                                            />
                                        </div>
                                        <div className="chatInput__requiredField">
                                            {INPUT_DISCLAIMER_TEXT}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(!queryResponse.length && !lastQuestionRef.current) && (!!maxChatSessionLimit && maxChatSessionLimit <= chatSessionHistory.length) && (
                        <div className="container__overlaylock">
                            <p><Image src={warningiconred} alt="warning" /> {replaceInLabel(MAX_SESSION_LIMIT_REACHED, '<value>', maxChatSessionLimit)}</p>
                            <p>{MAX_SESSION_LIMIT_REACHED_1}</p>
                        </div>
                    )}
                </div>
                {isOpenThoughtchain &&
                    <div className="detail-sec">
                        <ThoughtChain
                            onClose={() => setStateThoughtChain(false)}
                            data={thoughtChainData}
                        />
                    </div>
                }
                {isCitationSectionOpen &&
                    <div className="detail-sec">
                        <CitationSection
                            onClose={() => setCitationSectionOpen(false)}
                            imgSrc={selectedCitationSrc}
                            docvizData={selectedCitationDoc}
                        />
                    </div>
                }
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserChatSessionId: userChatSessionSelector.getActiveUserChatSession(state),
    isChatHistoryLoading: userChatSessionSelector.isChatHistoryLoading(state),
    isChatHistoryError: userChatSessionSelector.isChatHistoryError(state),
    citationSlides: slidesSelector.getCitationSlides(state),
    citationErrorSlides: slidesSelector.getCitationErrorSlides(state),
    maxChatSessionLimit: userChatSessionSelector.getMaxChatSession(state),
    chatSessionHistory: userChatSessionSelector.getChatSessionHistory(state),
    shouldDisableChatHistorySection: userChatSessionSelector.isChatSessionHistorySectionDisable(state),
    chatHistory: userChatSessionSelector.getChatHistory(state)
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserChatSession: () => dispatch(getActiveUserChatSession()),
    setCurrentUserChatSession: (data) => dispatch(getActiveUserChatSessionSuccess(data)),
    getUserChatHistory: (sessionId) => dispatch(getChatHistory(sessionId)),
    callSlidesAPI: (guid, slideNumber, chatId) => dispatch(getSlidesData(guid, slideNumber, chatId)),
    postFeedbackOfResponse: (data) => dispatch(postFeedbackResponse(data)),
    clearCurrentChatHistory: () => dispatch(clearChatHistory()),
    disableChatSessionHistorySection: (data) => dispatch(setHistoryChatSectionDisabled(data)),
});


export const ChatAssistant = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatAssistantContainer);

export { ChatAssistantContainer };
