import React from "react";
import "./Example.scss";

export const Example = ({ text, value, onClick }) => {
    return (
        <div className="example" onClick={() => onClick(value)}>
            <p className="exampleText">{text}</p>
        </div>
    );
};
