// constant
import { USER_CHAT_SESSION } from "redux/constants";
export const name = "userchatsession";

// initial state
export const initialState = {
  activeUserChatSession: {
    data: {},
    isLoading: false,
    error: ''
  },
  chatHistory: {
    data: {},
    isLoading: false,
    error: ''
  },
  chatSessionHistorySectionToggle: false,
  chatSessionHistorySectionDisable: false,
  chatSessionHistory: {
    data: {},
    isLoading: false,
    error: ''
  }
};

const {
  ACTIVE_USER_CHAT_SESSION_FAILURE,
  ACTIVE_USER_CHAT_SESSION_PENDING,
  ACTIVE_USER_CHAT_SESSION_SUCCESS,
  CREATE_USER_CHAT_SESSION_FAILURE,
  CREATE_USER_CHAT_SESSION_PENDING,
  CREATE_USER_CHAT_SESSION_SUCCESS,
  GET_CHAT_HISTORY_PENDING,
  GET_CHAT_HISTORY_FAILURE,
  GET_CHAT_HISTORY_SUCCESS,
  SET_CHAT_SESSION_HISTORY_SECTION_TOGGLE,
  SET_CHAT_SESSION_HISTORY_SECTION_DISABLE,
  GET_CHAT_SESSION_HISTORY_FAILURE,
  GET_CHAT_SESSION_HISTORY_PENDING,
  GET_CHAT_SESSION_HISTORY_SUCCESS,
  UPDATE_USER_CHAT_SESSION_SUCCESS
} = USER_CHAT_SESSION;

// reducer selectors
export const selectors = {
  getActiveUserChatSession: (state) => state[name].activeUserChatSession?.data?.chat_session_id || '',
  isActiveUserChatSessionLoading: (state) => state[name].activeUserChatSession.isLoading,
  isChatHistoryLoading: (state) => state[name].chatHistory.isLoading,
  isChatHistoryError: (state) => state[name].chatHistory.error,
  getChatHistory: (state) => state[name].chatHistory.data,
  isChatSessionHistorySectionToggle: (state) => state[name].chatSessionHistorySectionToggle,
  isChatSessionHistorySectionDisable: (state) => state[name].chatSessionHistorySectionDisable,
  getChatSessionHistory: (state) => state[name].chatSessionHistory?.data?.chat_session,
  getMaxChatSession: (state) => state[name].chatSessionHistory?.data?.max_chat_sessions
};

const setActiveUserChatSessionError = (state, payload) => {
  return Object.assign({}, state, {
    activeUserChatSession: {
      ...initialState.activeUserChatSession,
      error: payload.error
    },
  });
};

const setActiveUserChatSessionPending = (state) => {
  return Object.assign({}, state, {
    activeUserChatSession: {
      ...initialState.activeUserChatSession,
      isLoading: true
    },
  });
};

const setActiveUserChatSession = (state, payload) => {
  return Object.assign({}, state, {
    activeUserChatSession: {
      ...initialState.activeUserChatSession,
      data: payload.data
    },
  });
};

const setChatHistoryError = (state, payload) => {
  return Object.assign({}, state, {
    chatHistory: {
      ...initialState.chatHistory,
      error: payload.error
    },
  });
};

const setChatHistoryPending = (state) => {
  return Object.assign({}, state, {
    chatHistory: {
      ...initialState.chatHistory,
      isLoading: true
    },
  });
};

const setChatHistory = (state, payload) => {
  return Object.assign({}, state, {
    chatHistory: {
      ...initialState.chatHistory,
      data: payload.data
    },
  });
};

const setChatSessionHistorySectionToggle = (state, payload) => {
  return Object.assign({}, state, {
    chatSessionHistorySectionToggle: !!payload.data
  });
};

const setChatSessionHistoryError = (state, payload) => {
  return Object.assign({}, state, {
    chatSessionHistory: {
      ...initialState.chatSessionHistory,
      error: payload.error
    },
  });
};

const setChatSessionHistoryPending = (state) => {
  return Object.assign({}, state, {
    chatSessionHistory: {
      ...initialState.chatSessionHistory,
      isLoading: true
    },
  });
};

const setChatSessionHistorySuccess = (state, payload) => {
  return Object.assign({}, state, {
    chatSessionHistory: {
      ...initialState.chatSessionHistory,
      data: payload.data
    },
  });
};

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTIVE_USER_CHAT_SESSION_PENDING:
    case CREATE_USER_CHAT_SESSION_PENDING:
      return setActiveUserChatSessionPending(state);
    case ACTIVE_USER_CHAT_SESSION_FAILURE:
    case CREATE_USER_CHAT_SESSION_FAILURE:
      return setActiveUserChatSessionError(state, payload);
    case CREATE_USER_CHAT_SESSION_SUCCESS:
    case ACTIVE_USER_CHAT_SESSION_SUCCESS:
    case UPDATE_USER_CHAT_SESSION_SUCCESS:
      return setActiveUserChatSession(state, payload);
    case GET_CHAT_HISTORY_PENDING:
      return setChatHistoryPending(state);
    case GET_CHAT_HISTORY_FAILURE:
      return setChatHistoryError(state, payload);
    case GET_CHAT_HISTORY_SUCCESS:
      return setChatHistory(state, payload);
    case SET_CHAT_SESSION_HISTORY_SECTION_TOGGLE:
      return setChatSessionHistorySectionToggle(state, payload);
    case GET_CHAT_SESSION_HISTORY_PENDING:
      return setChatSessionHistoryPending(state);
    case GET_CHAT_SESSION_HISTORY_FAILURE:
      return setChatSessionHistoryError(state, payload);
    case GET_CHAT_SESSION_HISTORY_SUCCESS:
      return setChatSessionHistorySuccess(state, payload);
    case SET_CHAT_SESSION_HISTORY_SECTION_DISABLE:
      return {
        ...state,
        chatSessionHistorySectionDisable: !!payload.data,
      };
    default:
      return state;
  }
}
