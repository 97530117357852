import React from "react";
import Image from "components/shared/Image";
import warningiconred from "assets/images/warningiconred.svg";

import "./Answer.scss";

export const AnswerError = ({ error, onRetry }) => {
    return (
        <div className="answerContainer" verticalAlign="space-between">
            <Image src={warningiconred} />
            <div>
                <p className="answerText">{error}</p>
            </div>

            <button className="retryButton" onClick={onRetry}>Retry</button>
        </div>
    );
};
