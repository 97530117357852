import { put, takeLatest, call } from 'redux-saga/effects';
// Constant
import { ENTITLEMENTS, AUTH } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import {
    getEntitlementsPending,
    getEntitlementsSuccess,
    getEntitlementsFailure,
    checkSiteAdminPending,
    checkSiteAdminSuccess,
    checkSiteAdminFailure,
    getSmartLogicBearerTokenPending,
    getSmartLogicBearerTokenFailure,
    getSmartLogicBearerTokenSuccess
} from "./authorization.actions";
import { moduleFailure } from "redux/global.action.js";
// import { sendToCookie } from "utils/cookies/cookies";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';

export function* getEntitlements() {
    try {
        const { X_API_KEY, API_URL: { GET_ENTITLEMENT } } = CONFIG;
        yield put(getEntitlementsPending());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_ENTITLEMENT(),
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(getEntitlementsSuccess(res));
    } catch (error) {
        yield put(getEntitlementsFailure(error));
    }
}
// Saga to check if the user is site admin/site editor or not
export function* checkSiteAdmin() {
    try {
        const { X_API_KEY, API_URL: { SITE_ADMIN_EDITOR } } = CONFIG;
        yield put(checkSiteAdminPending());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: SITE_ADMIN_EDITOR,
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        if (res?.message === "true" || res?.message === "false") {
            yield put(checkSiteAdminSuccess(JSON.parse(res?.message)));
        }
        else {
            yield put(checkSiteAdminFailure(res?.message));
        }
    } catch (error) {
        yield put(checkSiteAdminFailure(error));
    }
}

export function* getSmartLogicBearerToken() {
    try {
        const {
            HTTP_CONTENT_TYPES: { urlencoded },
            X_API_KEY, API_KEYS: { SMARTLOGIC_API_KEY },
            API_URL: { GET_SMARTLOGIC_TOKEN
            } } = CONFIG;
        yield put(getSmartLogicBearerTokenPending());
        const res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: GET_SMARTLOGIC_TOKEN,
            params: { key: SMARTLOGIC_API_KEY, grant_type: "apikey" },
            headers: {
                noAuthHeader: true,
                'content-type': urlencoded,
                'x-api-key': X_API_KEY
            }
        });
        if (res && res['access_token'] && res['.expires']) {
            sendToLocalStorage(CONFIG.OKTA.SMARTLOGIC_TOKEN_KEY, res['access_token']);
            yield put(getSmartLogicBearerTokenSuccess(true));
        } else {
            yield put(getSmartLogicBearerTokenFailure(CONFIG.ERROR_MESSAGES.NOT_FOUND));
        }
    } catch (error) {
        yield put(getSmartLogicBearerTokenFailure(CONFIG.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* authorizationSaga() {
    try {
        yield takeLatest(ENTITLEMENTS.GET_ENTITLEMENTS, getEntitlements);
        yield takeLatest(ENTITLEMENTS.CHECK_SITE_ADMIN, checkSiteAdmin);
        yield takeLatest(AUTH.GET_SMARTLOGIC_BEARER_TOKEN, getSmartLogicBearerToken);

    } catch (error) {
        yield put(moduleFailure(error, 'authorizationSaga'));
    }
}