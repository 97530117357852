// constant
import { CASES } from "redux/constants";

// local variable
export const name = "case";

// initial state
export const initialState = {
    caseDetail: {
        loading: false,
        errorMessage: '',
        data: {}
    },
    relatedCases: {
    },
    casesMetaDetails: {
        loading: false,
        errorMessage: '',
        data: []
    },
};
// reducer selectors
export const selectors = {
    getLoading: (state) => state[name].caseDetail.loading,
    getError: (state) => state[name].caseDetail.errorMessage,
    getCasesMetaDetailsLoading: (state) => state[name].casesMetaDetails.loading,
    getCasesMetaDetailsError: (state) => state[name].casesMetaDetails.errorMessage,
    getCasesMetaDetails: (state) => state[name].casesMetaDetails.data,
    getCaseDetail: (state) => state[name].caseDetail.data,
    getRelatedCasesDetails: (state) => state[name].relatedCases
};

const setCaseDetailsPending = (state) => {
    return Object.assign({}, state, {
        caseDetail: {
            ...state.caseDetail,
            loading: true,
            errorMessage: ''
        },
    });
};

const setCaseDetails = (state, payload) => {
    return Object.assign({}, state, {
        caseDetail: {
            ...state.caseDetail,
            data: payload.res,
            errorMessage: '',
            loading: false,
        },
    });
};

const setCaseDetailsError = (state, payload) => {
    return Object.assign({}, state, {
        caseDetail: {
            ...state.caseDetail,
            errorMessage: payload.error,
            loading: false,
            data: {}
        },
    });
}
const setRelatedCaseDetailsError = (state, payload) => {
    return Object.assign({}, state, {
        caseDetail: {
            ...state.caseDetail
        },
        relatedCases: {
            ...state.relatedCases
        },
        errorMessage: payload.error,
        loading: false
    });
}

const setRelatedCaseDetailsPending = (state) => {
    return Object.assign({}, state, {
        caseDetail: {
            ...state.caseDetail
        },
        relatedCases: {
            ...state.relatedCases
        },
        loading: true,
        errorMessage: ''
    });
};

const setRelatedCaseDetails = (state, payload) => {
    const { res, caseid, typeofCase } = payload;
    const temp = { data: res, type: typeofCase };
    return Object.assign({}, state, {
        caseDetail: {
            ...state.caseDetail
        },
        relatedCases: {
            ...state.relatedCases,
            [caseid]: { ...temp }
        },
        errorMessage: '',
        loading: false,
    });
};


const setCasesMetaDetailsPending = (state) => {
    return Object.assign({}, state, {
        casesMetaDetails: {
            ...state.casesMetaDetails,
            loading: true,
            errorMessage: ''
        },
    });
};

const setCasesMetaDetails = (state, payload) => {
    return Object.assign({}, state, {
        casesMetaDetails: {
            ...state.casesMetaDetails,
            data: payload.res,
            errorMessage: '',
            loading: false,
        },
    });
};

const setCasesMetaDetailsError = (state, payload) => {
    return Object.assign({}, state, {
        casesMetaDetails: {
            ...state.casesMetaDetails,
            errorMessage: payload.error,
            loading: false,
            data: {}
        },
    });
}

// reducer
export function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CASES.API_GET_CASE_DETAILS_PENDING:
            return setCaseDetailsPending(state);
        case CASES.API_GET_CASE_DETAILS_SUCCESS:
            return setCaseDetails(state, payload);
        case CASES.API_GET_CASE_DETAILS_FAILURE:
            return setCaseDetailsError(state, payload);
        case CASES.API_GET_RELATED_CASE_DETAIL_PENDING:
            return setRelatedCaseDetailsPending(state);
        case CASES.API_GET_RELATED_CASE_DETAIL_SUCCESS:
            return setRelatedCaseDetails(state, payload);
        case CASES.API_GET_RELATED_CASE_DETAIL_FAILURE:
            return setRelatedCaseDetailsError(state, payload);

        case CASES.API_GET_CASES_META_DETAILS_PENDING:
            return setCasesMetaDetailsPending(state);
        case CASES.API_GET_CASES_META_DETAILS_SUCCESS:
            return setCasesMetaDetails(state, payload);
        case CASES.API_GET_CASES_META_DETAILS_FAILURE:
            return setCasesMetaDetailsError(state, payload);

        default:
            return state;
    }
}
