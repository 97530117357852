// Constant
import { CASES } from "redux/constants";

export const getCaseDetailsPending = () => ({
    type: CASES.API_GET_CASE_DETAILS_PENDING,
    payload: {
        res: ''
    }
});

export const getCaseDetailsSuccess = (res) => ({
    type: CASES.API_GET_CASE_DETAILS_SUCCESS,
    payload: {
        res
    }
});

export const getCaseDetailsFailure = (error) => ({
    type: CASES.API_GET_CASE_DETAILS_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getRelatedCaseDetailPending = (caseid, typeofCase) => ({
    type: CASES.API_GET_RELATED_CASE_DETAIL_PENDING,
    payload: {
        caseid: caseid,
        res: '',
        typeofCase: typeofCase
    }
});
export const getRelatedCaseDetailSuccess = (caseid, res, typeofCase) => ({
    type: CASES.API_GET_RELATED_CASE_DETAIL_SUCCESS,
    payload: {
        caseid: caseid,
        res: res,
        typeofCase: typeofCase
    }
});

export const getRelatedCaseDetailFailure = (error) => ({
    type: CASES.API_GET_RELATED_CASE_DETAIL_FAILURE,
    payload: {
        error: error.toString()
    }
});


export const getCasesMetaDetails = (caseNumbers) => ({
    type: CASES.GET_CASES_META_DETAILS,
    payload: {
        caseNumbers: caseNumbers
    }
});


export const getCasesMetaDetailsPending = () => ({
    type: CASES.API_GET_CASES_META_DETAILS_PENDING,
});

export const getCasesMetaDetailsSuccess = (res) => ({
    type: CASES.API_GET_CASES_META_DETAILS_SUCCESS,
    payload: {
        res
    }
});

export const getCasesMetaDetailsFailure = (error) => ({
    type: CASES.API_GET_CASES_META_DETAILS_FAILURE,
    payload: {
        error: error.toString()
    }
});
