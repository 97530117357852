import React, { useState, useEffect } from "react";
import { getUserFullnameFromToken, getUserInitials } from "utils/helpers/helpers";

import './ProfileIcon.scss'

export const ProfileIcon = () => {

    useEffect(() => {
        // call the function to get the user initial
        const fullname = getUserFullnameFromToken();
        const userInitials = getUserInitials(fullname, true);
        setUserFullname(fullname);
        setUserInitials(userInitials);
    }, []);

    const [userInitials, setUserInitials] = useState('');
    const [userFullname, setUserFullname] = useState('');

    return (
        <div className="profileicon__wrap" title={userFullname} data-title={userFullname}>
            {userInitials}
        </div>
    )
}