import React from 'react';

import LABELS from 'labels'

import './FollowupQuestion.scss';

export const FollowupQuestion = ({
    onFollowupQuestionClicked,
    queryResponse = [[]]
}) => {

    const {
        FOLLOWUP_QUESTIONS
    } = LABELS;

    // const queryResponseTemp = [
    //     ['abc', {
    //         followup_questions: [
    //             'What is oil1'
    //         ]
    //     }],
    //     ['abc', {
    //         followup_questions: [
    //             'What is oil2'
    //         ]
    //     }],
    //     ['abc', {
    //         followup_questions: [
    //             'As a consultant give me pointers on why',
    //             'As a consultant give me pointers on why BCG has a strategic advantage to support Early Childhood education due to the work BCG has done in the past',
    //             'What is Design Thinking',
    //             'Give me a list of tools that BCG has to enable building Job Architecture, Skills and Career Paths for an organization',
    //             'As a consultant give me pointers on why BCG has a strategic advantage'
    //         ]
    //     }]
    // ]
    // console.log('queryResponse', queryResponse);
    let lastElementResponse;

    if (!!queryResponse && queryResponse.length !== 0) {
        lastElementResponse = queryResponse.slice(-1)[0][1]; // Get the last queryResponse element and from that get the response element
    }

    // console.log('lastElementResponse', lastElementResponse);
    let followup_questions;

    if (!!lastElementResponse && lastElementResponse?.followup_questions) {
        followup_questions = lastElementResponse?.followup_questions;
    }

    // console.log('followup_questions', followup_questions);

    return (
        <div className='followupquestion__wrap'>
            {!!lastElementResponse && lastElementResponse?.followup_questions &&
                <>
                    <span className='followupquestion__title'>{FOLLOWUP_QUESTIONS}</span>
                    <section className='followupquestion__list'>
                        {
                            followup_questions.map((followup_question, i) => {
                                return (
                                    <span key={i} className={"followupquestion__item"} onClick={() => onFollowupQuestionClicked(followup_question)}>
                                        {`${followup_question}`}
                                    </span>
                                );
                            })
                        }
                    </section>
                </>
            }
        </div>

    )
}