import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { RESPONSE_DATA, FEEDBACK } from "redux/constants";
import { createUserChatSession, getChatSessionHistory } from '../userchatsession/userchatsession.saga';

import {
    getResponseLoading,
    getResponseFailure,
    getResponseSuccess,

    postFeedbackResponseLoading,
    postFeedbackResponseSuccess,
    postFeedbackResponseFailure
} from "./response.actions";

import {
    createUserChatSessionSuccess,
} from "../userchatsession/userchatsession.actions";

const { QUERY_RESPONSE } = RESPONSE_DATA;
const { POST_FEEDBACK_RESPONSE } = FEEDBACK;

export function* getQueryResonse(action) {
    const { payload: {
        chatSessionId,
        question
    } } = action;
    let queryArray;
    try {
        const { X_API_KEY, API_URL: { GET_RESPONSE } } = CONFIG;
        yield put(getResponseLoading());
        let newChatSessionId, createUserChatSessionResponse
        if (!chatSessionId) {
            createUserChatSessionResponse = yield call(createUserChatSession, {
                payload: {
                    data: '',
                }
            });
            newChatSessionId = createUserChatSessionResponse?.chat_session_id;
        }
        else newChatSessionId = chatSessionId;
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_RESPONSE(newChatSessionId, question),
            headers: {
                'x-api-key': X_API_KEY
            }
        });

        queryArray = [question, res];
        yield put(getResponseSuccess(queryArray));
        if (!chatSessionId) {
            yield put(createUserChatSessionSuccess(createUserChatSessionResponse));
            yield call(getChatSessionHistory, {});
        }

        if (res?.is_title_updated) {
            yield call(getChatSessionHistory, {});
        }
    } catch (error) {
        console.error("error: ", error)
        yield put(getResponseFailure(error?.message));
    }
}

export function* postFeedbackOfResponse(action) {
    try {
        const {
            payload: {
                chatId,
                feedback,
                comments,
            }
        } = action;
        const { X_API_KEY, API_URL: { POST_FEEDBACK_RESPONSE } } = CONFIG;
        yield put(postFeedbackResponseLoading());

        const endpoint = POST_FEEDBACK_RESPONSE(chatId);
        let res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: endpoint,
            params: {
                feedback,
                comment: comments
            },
            headers: {
                'x-api-key': X_API_KEY
            }
        });
        yield put(postFeedbackResponseSuccess(res));

    } catch (error) {
        console.error("error: ", error)
        yield put(postFeedbackResponseFailure(error?.message));
    }
}


export default function* ResponseSaga() {
    try {
        yield takeLatest(QUERY_RESPONSE, getQueryResonse);
        yield takeLatest(POST_FEEDBACK_RESPONSE, postFeedbackOfResponse);
    } catch (error) {
        yield put(moduleFailure(error, 'ResponseSaga'));
    }
}