import React from 'react';
import PropTypes from "prop-types";
import thoughtchainIcon from 'assets/images/thoughtchain_icon.svg';
import crossIcon from 'assets/images/cross.svg';
import Image from "components/shared/Image";
import LABELS from 'labels';
import CONFIG from "config";

import "./ThoughtChain.scss";

class ThoughtChain extends React.PureComponent {

  render() {
    const {
      onClose,
      data: {
        query,
        documents_Search,
        documents_Selected
      }
    } = this.props;
    // const { } = this.state;
    const { THOUGHT_CHAIN_HEADING,
      THOUGHT_CHAIN_SEARCHED_FOR,
      THOUGHT_CHAIN_DOCUMENT_RETRIEVED,
      THOUGHT_CHAIN_DOCUMENT_SOURCED
    } = LABELS;

    const {
      API_URL: {
        LINK_PAGES_REDIRECT
      }
    } = CONFIG;

    return (
      <div className="thoughtchain__wrap">
        <div className="thoughtchain__heading">
          <div className='thoughtchain__heading-left'>
            <Image
              src={thoughtchainIcon}
              alt="arrow"
            />
            <h3>{THOUGHT_CHAIN_HEADING}</h3>
          </div>
          <ul className="thoughtchain__heading-right">
            {/* <li className="thoughtchain__pill">
              <span className="thoughtchain__iconWrap">
                <Image
                  src={collectionIcon}
                  alt="collection icon"
                />
              </span>
              <h5>{ADD_TO_COLLECTION}</h5>
            </li>
            <li className="thoughtchain__pill">
              <span className="thoughtchain__iconWrap">
                <Image
                  src={downloadIcon}
                  alt="download icon"
                />
              </span>
              <h5>{DOWNLOAD}</h5>
            </li> */}
            <li className="thoughtchain__pill thoughtchain__cross">
              <Image
                src={crossIcon}
                alt="cross icon"
                onClick={onClose}
              />
            </li>
          </ul>
        </div>
        <div className="thoughtchain__body">
          <div className="thoughtchain__body__query">
            <span>{THOUGHT_CHAIN_SEARCHED_FOR}</span>
            <p>&quot;{query}&quot;</p>
          </div>
          <div className="thoughtchain__body__sourced">
            <span>{THOUGHT_CHAIN_DOCUMENT_RETRIEVED}</span>
            <ul>
              {
                documents_Search?.map((document, index) => {
                  const {
                    identifier,
                    title
                  } = document;
                  return (
                    <li key={`${index}-${identifier}-r`}>
                      . <a href={`${LINK_PAGES_REDIRECT}/kp/${identifier}`} title={title} target="_blank" rel="noopener noreferrer">{title}</a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="thoughtchain__body__searched">
            <span>{THOUGHT_CHAIN_DOCUMENT_SOURCED}</span>
            {
              documents_Selected.map((document, index) => {

                const {
                  title,
                  kp_cms_id,
                  content
                } = document;
                return (
                  <div key={`${index}-${kp_cms_id}-s`}>
                    . <a href={`${LINK_PAGES_REDIRECT}/kp/${kp_cms_id}`} title={title} target="_blank" rel="noopener noreferrer">{title}</a>
                    <p>{content}</p>
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
    );
  }
}

ThoughtChain.prototypes = {
  onClose: PropTypes.func
}

ThoughtChain.defaultProps = {
  onClose: () => { }
}

export { ThoughtChain };
