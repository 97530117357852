import React from "react";
import { Example } from "./Example";

import "./Example.scss";

export const ExampleList = ({ data = [], onExampleClicked }) => {
    return (
        <ul className="examplesNavList">
            {data.map((x, i) => (
                <li key={i}>
                    <Example text={x} value={x} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
