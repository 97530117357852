import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
import { SUGGESTED_QUERY_DATA } from "redux/constants";

import {
    getSuggestedQueryLoading,
    getSuggestedQueryFailure,
    getSuggestedQuerySuccess
} from "./suggestedquery.actions";

const {
    SUGGESTED_QUERY
} = SUGGESTED_QUERY_DATA;

export function* getSuggestedQuery(action) {
    try {
        const { X_API_KEY, API_URL: { GET_SUGGESTED_QUERY } } = CONFIG;
        yield put(getSuggestedQueryLoading());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_SUGGESTED_QUERY,
            headers: {
                'x-api-key': X_API_KEY
            }
        });

        yield put(getSuggestedQuerySuccess(res?.results));
    } catch (error) {
        console.error("error: ", error)
        yield put(getSuggestedQueryFailure(error?.message));
    }
}


export default function* SuggestedQuerySaga() {
    try {
        yield takeLatest(SUGGESTED_QUERY, getSuggestedQuery);
    } catch (error) {
        yield put(moduleFailure(error, 'SuggestedQuerySaga'));
    }
}