import React from "react";
import Image from "components/shared/Image";
import logoIcon from "assets/images/logo.svg";
import { ProfileIcon } from "components/ProfileIcon";
import "./Header.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="mainheader__wrap">
        <div className="mainheader__logo">
          <Image src={logoIcon} alt="logo" />
          <div className="mainheader__logo--text">
            <p>Proposal GPT</p>
            <h1>Proposal Builder Assistant</h1>
          </div>
        </div>
        <div className="mainheader__userProfile">
          <ProfileIcon />
        </div>
      </div>
    );
  }
}

Header.prototypes = {
  // finalSuccess: PropTypes.func
}

export { Header };
